<template>
  <v-container v-if="user" style="max-width: 1280px">
    <PageTitle title="Dashboard" subtitle="Alles im grünen Bereich! 😊"></PageTitle>
      <v-row class="mb-6">
        <DashboardCube
          title="Verbrauchte Kalorien"
          :count="calories"
          unit="kcal"
        />
        <DashboardCube
          title="Erledigte Aktivitäten"
          :count="done.length"
        />
        <DashboardCube
          title="Gewicht"
          :count="weight"
          unit="kg"
        />
        <DashboardCube
          title="Essensvorschlag öffnen"
          icon="mdi-food-apple"
          @click="$router.push('/foodsuggestion')"
        />
      </v-row>
      <v-row>
        <!-- Bevorstehende Aktivitäten -->
        <v-col
          cols="12"
          md="6"
        >
          <v-card color="primary" class="pa-5">
            <h3 class="white--text headline">Bevorstehende Aktivitäten</h3>
            <activity-entry
              v-for="da in todo"
              v-bind:key="da.id"
              :dailyactivity="da"
              @change="patch"
            />
            <v-card-text v-if="todo.length === 0" class="white--text text-center">Keine Daten vorhanden</v-card-text>
          </v-card>
        </v-col>
        <!-- Erledigte Aktivitäten -->
        <v-col
          cols="12"
          md="6"
        >
          <v-card color="primary" class="pa-5">
            <h3 class="white--text headline">Erledigte geplante Aktivitäten</h3>
            <activity-entry
              v-for="da in done"
              v-bind:key="da.id"
              :dailyactivity="da"
              @change="patch"
            />
            <v-card-text v-if="todo.length === 0" class="white--text text-center">Keine Daten vorhanden</v-card-text>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import DashboardCube from '@/components/DashboardCube.vue';
import ActivityEntry from '@/components/ActivityEntry.vue';
import User from '@/model/User';
import EventBus from '@/util/eventBus';

export default {
  name: 'Dashboard',

  components: {
    ActivityEntry,
    DashboardCube,
    PageTitle,
  },

  data() {
    return {
      user: null,
    };
  },

  mounted() {
    User.GetInstance()
      .then((instance) => {
        this.user = instance;
      })
      .catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });
  },

  computed: {
    calories() {
      return Math.round(this.user.latest_weight * 13.37 * 0.69);
    },
    weight() {
      if (this.user.latest_weight) {
        return Math.round(this.user.latest_weight * 100) / 100;
      }
      return '-';
    },
    done() {
      return this.user.dailyactivities.filter((da) => da.DoneToday());
    },
    todo() {
      return this.user.dailyactivities.filter((da) => !da.DoneToday());
    },
  },

  methods: {
    /**
     * Will update the done state of a daily activity
     * @param dailyactivity Object of the daily activity
     * @param {boolean|undefined} val Is the activity done?
     */
    patch(dailyactivity, val) {
      if (val) {
        // ms auf s umrechnen
        dailyactivity.done.push(new Date().getTime() / 1000);
        this.user.PatchDailyactivityDone(dailyactivity)
          .catch((e) => {
            EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
          });
      } else {
        this.user.DeleteDailyactivityDone(dailyactivity)
          .catch((e) => {
            EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
          });
      }
    },
  },
};
</script>
