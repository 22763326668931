<template>
  <v-container v-if="user" style="max-width: 1280px">
    <PageTitle title="🎉 Großartig! 🎉" subtitle="Feedback zur letzen Woche"></PageTitle>

    <v-row class="">
      <v-col cols="12" sm="4" class="text-center">
        <div :style="{ color: sleep.good ? 'limegreen' : 'red'}">
          <span class="text-h3">{{ sleep.value }} </span>
          <span>h/Tag</span>
        </div>
        <div class="text-h3">{{ sleep.good ? '👍' : '👎' }}</div>
        <div class="text-h3">Schlafverhalten</div>
        <div class="">{{ sleep.description }}</div>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row class="">
      <v-spacer/>
      <v-col cols="12" sm="4" class="text-center">
        <div :style="{ color: calories.good ? 'limegreen' : 'red'}">
          <span class="text-h3">{{ calories.value }} </span>
          <span>kcal/Tag</span>
        </div>
        <div class="text-h3">{{ calories.good ? '👍' : '👎' }}</div>
        <div class="text-h3">Kalorienverbrauch</div>
        <div class="">{{ calories.description }}</div>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row class="">
      <v-spacer/>
      <v-spacer/>
      <v-col cols="12" sm="4" class="text-center">
        <div :style="{ color: weight.good ? 'limegreen' : 'red'}">
          <span class="text-h3">{{ weight.value }} </span>
          <span>kg</span>
        </div>
        <div class="text-h3">{{ weight.good ? '👍' : '👎' }}</div>
        <div class="text-h3">Gewicht</div>
        <div class="">{{ weight.description }}</div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import User from '@/model/User';
import EventBus from '@/util/eventBus';

export default {
  name: 'Feedback',

  components: {
    PageTitle,
  },

  data() {
    return {
      user: null,
      now: this.$moment(),
      sleep: {
        value: '-',
        good: true,
        description: '',
      },
      calories: {
        value: '-',
        good: false,
        description: 'Du solltest mehr Kalorien verbrauchen!',
      },
      weight: {
        value: '-',
        good: true,
        description: '',
      },
    };
  },

  mounted() {
    User.GetInstance()
      .then((instance) => {
        this.user = instance;
        this.Calc_Sleep();
        this.Calc_Calories();
        this.Calc_Weight();
      })
      .catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });
  },

  computed: {
  },

  methods: {
    /**
     * Calculates the average sleep time of the last seven days and evaluates it
     * @constructor
     */
    Calc_Sleep() {
      const descGood = 'Du hältst deine Schlafzeiten optimal ein!';
      const descBad = 'Du solltest dich besser an deine Schlafzeit halten!';
      let sum = 0; // sum of sleep in this week in seconds
      const lastWeek = this.now.clone().subtract(7, 'days').startOf('day');
      const valuedSleeps = this.user.sleeps.filter((sleep) => this.$moment(sleep.start * 1000).isAfter(lastWeek));
      if (valuedSleeps.length > 0 && this.user.sleep_start && this.user.sleep_end) {
        const sleepStart = this.$moment.duration(this.user.sleep_start);
        let sleepEnd = this.$moment.duration(this.user.sleep_end);

        if (sleepStart > sleepEnd) { // wenn sleepEnd > sleepStart: bspw: 22:00 > 06:00
          sleepEnd = sleepEnd.add(1, 'days');
        }
        const userSetSleep = sleepEnd - sleepStart;

        valuedSleeps.forEach((sleep) => {
          sum += sleep.end - sleep.start;
        });

        const msecPerDay = (sum / 7) * 1000;
        this.sleep.value = this.$moment.duration(msecPerDay)
          .format('HH:mm');
        this.sleep.good = msecPerDay >= userSetSleep;
        this.sleep.description = this.sleep.good ? descGood : descBad;
      }
    },
    /**
     * Calculates the average calories burned
     * @return {number} average calories burned
     * @constructor
     */
    Calc_Calories() {
      return 1;
    },
    /**
     * Calculates the average weight change of the last seven days and evaluates it
     * @constructor
     */
    Calc_Weight() {
      const descGood = 'Du bist auf einem guten Weg!';
      const descBad = 'Du solltest dich besser an deine Schlafzeit halten!';

      const lastWeek = this.now.clone().subtract(7, 'days').startOf('day');
      // eslint-disable-next-line max-len
      const latestWeight = this.user.weights.filter((weight) => this.$moment(weight.timestamp * 1000).isAfter(lastWeek)).sort((a, b) => a.timestamp - b.timestamp).pop();
      // eslint-disable-next-line max-len
      const latestWeightLW = this.user.weights.filter((weight) => !this.$moment(weight.timestamp * 1000).isAfter(lastWeek)).sort((a, b) => a.timestamp - b.timestamp).pop();

      if (latestWeight && latestWeightLW) {
        const wDiff = latestWeight.value - latestWeightLW.value;
        this.weight.value = wDiff.toPrecision(2);
        if (wDiff >= 0) {
          this.weight.description = descGood;
          this.weight.good = false;
        } else {
          this.weight.description = descBad;
          this.weight.good = true;
        }
      }
    },
  },
};
</script>
