<template>
  <v-layout>
    <v-select
      :items="items"
      :value="value"
      @input="(v) => $emit('input', v)"
      label="Tägliche Aktivität"
      item-text="name"
      item-value="icon"
      clearable
      return-object
      :disabled="disabled"
    >
      <template v-slot:prepend>
        <img
          :src="require('../assets/icons/'+ (value ? value.icon : 'no.png'))"
          class="selectIcon"
        >
      </template>
      <template v-slot:item="{ item }">
        <img
          :src="require('../assets/icons/' + item.icon)"
          class="selectIcon mr-3"
        >
        <span>{{ item.name }}</span>
      </template>
    </v-select>

  </v-layout>
</template>

<script>
export default {
  name: 'MyDailyActivityPicker',
  props: {
    value: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
  }),
};
</script>

<style scoped>
  .selectIcon {
    height: 15px;
  }

</style>
