<template>
  <v-container>
    <PageTitle title="Registrieren"></PageTitle>
    <v-row style="margin-top: 10vh"
           align="center"
           justify="center">
      <v-card color="primary" >
        <v-form v-model="valid">
          <v-card-text c>
            <table>
              <tr>
                <td class="title">Username:</td>
                <td>
                  <v-text-field
                    class="inputfield"
                    v-model="name"
                    :rules="[rules.required, rules.max]"
                  />
                </td>
              </tr>
              <tr>
                <td class="title">Passwort:</td>
                <td>
                  <v-text-field
                    class="inputfield"
                    type="password"
                    v-model="password"
                    :rules="[rules.required, rules.max, rules.minpw, rules.pw]"
                  />
                </td>
              </tr>
            </table>
            <router-link
              to="/"
              tag="a">
              Sie haben bereits ein Konto? <b>Hier anmelden!</b>
            </router-link>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="secondary"
              @click="register"
              :loading="loading"
              :disabled="!valid"
            >
              Registrieren
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import User from '@/model/User';
import PageTitle from '@/components/PageTitle.vue';
import { fetchAPI } from '@/util/fetch';
import EventBus from '@/util/eventBus';
import { handle } from '@/subscribe';

export default {
  name: 'Registrieren',
  components: {
    PageTitle,
  },
  data() {
    return {
      loading: false,
      valid: false,
      name: '',
      password: '',
      header: new Headers({
        'Content-Type': 'application/json',
      }),
      // Rules for the password
      rules: {
        required: (value) => !!value || 'Pflicht.',
        max: (value) => value.length <= 255 || 'Max. 255 Zeichen',
        minpw: (value) => value.length >= 8 || 'Min. 8 Zeichen',
        // eslint-disable-next-line max-len
        pw: (value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%?&#])[A-Za-z\d$@$!%?&#]{8,}$/.test(value) || 'Password muss mindestens: 1 Kleinbuchstaben, 1 Großbuchstaben, 1 Zahl, 1 Sonderzeichen enthalten',
      },
    };
  },

  created() {
    // if we accidentally get here while logged in -> go to dashboard
    const token = User.GetToken();
    if (this.checkToken(token)) {
      this.$router.push('/dashboard');
    }
  },

  methods: {
    /**
     * Check JWT Token
     * @param token JWT token
     * @return {boolean} expired
     */
    checkToken(token) {
      if (token) {
        const decoded = jwt_decode(token);
        if (Date.now() < decoded.exp * 1000) {
          return true;
        }
      }
      return false;
    },
    /**
     * Register a new user and subscribe to PushNotification
     */
    register() {
      this.loading = true;
      const body = JSON.stringify({
        name: this.name,
        password: this.password,
      });

      fetchAPI('/register', { method: 'POST', headers: this.header, body }).then((res) => {
        if (this.checkToken(res.token)) {
          User.SetToken(res.token);
          EventBus.$emit('user-login');
        } else {
          throw new Error(res);
        }

        // Subscribe user for PushNotifications
        if ('PushManager' in window) {
          navigator.serviceWorker.ready
            .then((worker) => {
              handle(worker, User.GetToken());
            });
        }
      }).catch((e) => {
        const res = typeof e === 'string' ? JSON.parse(e) : e;
        let text = `Unbekannter Fehler beim registrieren: ${e}`;
        if (res.password) {
          text = `Fehler beim registrieren: ${res.password}`;
        } else if (res.username) {
          text = `Fehler beim registrieren: ${res.username}`;
        }
        EventBus.$emit('new-snackbar', text, 'error', 5000, true);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
  .inputfield {
    width: 250px;
  }

  th, td {
    padding: 5px;
  }

  .inputfield >>> .v-text-field__slot input {
    background-color: white
  }
</style>
