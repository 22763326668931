<template>
    <v-app>
        <ActivitySummaryDialog
                :show-dialog="summaryDialog"
                :stopwatch-string="stopwatchString"
                :count="counter"
        />
        <v-layout class="text-center"
                  column fill-height align-center justify-center
                  style="flex-basis: 0; user-select: none" @click="counter += 1">
            <v-flex>
                <PageTitle :title="activity.text" class="pt-3"></PageTitle>
            </v-flex>
            <v-flex>
                <h1 class="display-3">{{stopwatchString}}</h1>
                <h2 class="font-weight-light">Dauer</h2>
            </v-flex>
            <v-flex>
                <h1 class="display-4">{{counter}}<span
                        class="title font-weight-medium">Stück</span></h1>
            </v-flex>
            <v-flex>
                <h1 class="display-1 font-weight-thin">
                    Tippe auf den Bildschirm um die Anzahl zu erhöhen
                </h1>
            </v-flex>
            <v-flex>
                <v-btn class="ma-16" color="primary" @click="endActivity">
                    Aktivität beenden
                </v-btn>
            </v-flex>
            <v-flex>
                <div style="height: 128px; "></div>
            </v-flex>
        </v-layout>
    </v-app>
</template>

<script>
import Stopwatch from 'statman-stopwatch';
import User from '@/model/User';
import Activity from '@/model/Activity';
import { hhmmss } from '@/util/time';
import EventBus from '@/util/eventBus';
import PageTitle from './PageTitle.vue';
import ActivitySummaryDialog from './ActivitySummaryDialog.vue';

export default {
  name: 'ActivityClicker',
  components: {
    ActivitySummaryDialog,
    PageTitle,
  },
  props: {
    activity: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    counter: 0,
    stopwatch: {
      time: 0, watcher: null, start: null, end: null,
    },
    user: null,
    summaryDialog: false,

  }),
  computed: {
    stopwatchString() {
      return hhmmss(this.stopwatch.time);
    },
  },
  mounted() {
    // Get user data from API
    User.GetInstance()
      .then((usr) => {
        this.user = usr;
      })
      .catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });

    // Create and start stopwatch
    this.stopwatch.watcher = new Stopwatch(true);
    this.stopwatch.start = Math.round(Date.now() / 1000);

    // Display the stopwatch count every second
    setInterval(() => {
      this.stopwatch.time = Math.round(this.stopwatch.watcher.read(0) / 1000);
    }, 1000);
  },
  beforeDestroy() {
    // Deletes information about the current activity because it is ended now
    localStorage.removeItem('currentActivity');
  },
  methods: {
    /**
     * Ends the activity. Stops the stopwatch and sends the collected data to the backend
     */
    endActivity() {
      this.counter -= 1; // remove one tap, because a tap on finish button increments the counter
      this.stopwatch.watcher.stop();
      const activityData = {
        calories: null,
        counter: this.counter,
      };
      const endedActivity = new Activity(
        -1,
        this.activity.type,
        this.stopwatch.start,
        Math.round(Date.now() / 1000),
        activityData,
      );
      this.user.AddActivity(endedActivity)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
      this.summaryDialog = true;
    },
  },

};
</script>
