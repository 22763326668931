<template>
    <v-card color="white" class="my-2">
        <v-card-text style="color: black" class="pa-2">
            <v-row align="center" justify="center" class="text-center">
              <v-col cols="2">
                <img :src="require('../assets/icons/' + (dailyactivity.icon || 'situps.png'))" class="sportIcon">
              </v-col>

              <v-col :cols="dailyActivityDoneToday ? 5 : 7">
                <span class="ml-2 headline">{{ dailyactivity.name }}</span>
              </v-col>

              <v-col cols="2" v-if="dailyActivityDoneToday">
                <span class="font-weight-bold">{{ $moment(dailyActivityDoneToday).format('HH:mm') }}</span><br>
              </v-col>
              <v-col cols="1">
                <v-checkbox
                  v-bind:input-value="dailyactivity.DoneToday()"
                  v-on:change="done"
                  color="error"
                />
              </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import 'moment';

export default {
  name: 'ActivityEntry',

  props: {
    dailyactivity: {
      type: Object,
      required: true,
    },
  },

  computed: {
    /**
     * Returns the timestamp when the done status was set for this activity under the condition that
     * it was completed today. Otherwise it will return false.
     * @return timestamp when done status was set today, otherwise false
     */
    dailyActivityDoneToday() {
      return this.dailyactivity.DoneToday() ? Math.max(...this.dailyactivity.done) * 1000 : false;
    },
  },

  methods: {
    /**
     * Toggles the done status of a daily activity if it was completed today
     */
    done() {
      if (this.dailyactivity.DoneToday()) {
        this.$emit('change', this.dailyactivity, false); // undo
      } else {
        this.$emit('change', this.dailyactivity, true); // do
      }
    },
  },
};
</script>

<style scoped>
    .sportIcon{
        height: 35px;
    }
</style>
