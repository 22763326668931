class Sleep {
  /**
   * Sleep
   * @param id
   * @param start Timestamp when the user began to sleep
   * @param end Timestamp when the user ended his sleep
   */
  constructor(id, start, end) {
    this.id = id;
    this.start = start;
    this.end = end;
  }
}

export default Sleep;
