<template>
    <div class="pageContent text-center mb-12">
        <h1
                :class="[($vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'), 'font-weight-medium']">
            {{ title }}
        </h1>
        <h1 v-if="subtitle"
                :class="[($vuetify.breakpoint.smAndDown ? 'title' : 'subtitle'), 'font-weight-light']">
            {{ subtitle }}
        </h1>
    </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      default: 'Beispiel Überschrift',
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<style scoped>

</style>
