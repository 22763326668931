class AutomatedGoal {
  /**
   * AutomatedGoal
   * @param {number} id
   * @param {string} name Name of the goal
   * @param {Date} created Date when goal is created
   * @param {Date} end Date when goal ends
   * @param {string} icon Icon of the goal
   * @param {DailyActivity} dailyActivity Holds the activity of the goal
   * @param {number} quantity How much units of the activity must be done
   * @param {number} progress Progress until the goal is reached in per cent
   */
  constructor(id, name, created, end, dailyActivity, icon, quantity, progress) {
    this.id = id;
    this.name = name;
    this.created = created;
    this.end = end;
    this.dailyActivity = dailyActivity; // This attribute will not be delivered by backend
    this.quantity = quantity;
    this.progress = progress || null;
    this.icon = icon || null;
  }
}

export default AutomatedGoal;
