<template>
  <v-container>
    <!-- Termin hinzufügen -->
    <v-card
      rounded
      :tile="tile"
      :color="colored ? appointment.color : 'primary'"
    >
      <v-card-title style="color: whitesmoke" v-if="!colored">
        Termin hinzufügen
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <!-- name row -->
          <MyFormRow
            title="Name"
          >
            <v-text-field
              v-model="appointment.name"
              filled
              clearable
              background-color="white"
              :rules="[rules.required, rules.min, rules.max]"
            />
          </MyFormRow>

          <!-- tag row -->
          <MyFormRow
            title="Tag"
          >
            <v-menu
              v-model="dayMenu"
              :close-on-content-click="false"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjs"
                  readonly
                  filled
                  prepend-inner-icon="mdi-calendar"
                  background-color="white"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dayField = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-bind:value="isodate"
                v-on:input="setAppointmentDate"
                locale="de-DE"
                full-width
                @change="dayMenu = false"
              ></v-date-picker>
            </v-menu>
          </MyFormRow>

          <!-- Zeitraum row-->
          <MyFormRow
            title="Zeitraum"
            :col="false"
          >
            <v-col
              :cols="$vuetify.breakpoint.xsOnly? 12 : null"
            >
              <v-menu
                ref="menuStart"
                v-model="startMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind:value="getAppointmentStartHour"
                    :disabled="!appointment.start"
                    label="Startzeit"
                    prepend-inner-icon="mdi-clock-time-two-outline"
                    readonly
                    filled
                    background-color="white"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-bind:value="getAppointmentStartHour"
                  v-on:input="setAppointmentStart"
                  format="24hr"
                  full-width
                  @click:minute="startMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.xsOnly? 12 : null"
            >
              <v-menu
                ref="menuEnd"
                v-model="endMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind:value="getAppointmentEndHour"
                    :disabled="!appointment.start"
                    label="Endzeit"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    filled
                    background-color="white"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-bind:value="getAppointmentEndHour"
                  v-on:input="setAppointmentEnd"
                  :min="getAppointmentStartHour"
                  format="24hr"
                  full-width
                  @click:minute="endMenu = false"
                />
              </v-menu>
            </v-col>
          </MyFormRow>

          <!-- color picker -->
          <v-menu
            ref="colorMenu"
            v-model="colorMenu"
            :close-on-content-click="false"
            offset-overflow
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                large
                :color="appointment.color"
                style="border: 2px solid black !important;"
                v-bind="attrs"
                v-on="on"
                @click="colorMenu = true"
              />
            </template>
            <v-color-picker
              v-model="appointment.color"
              class="no-alpha"
              hide-inputs
            />
          </v-menu>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          :disabled="!valid"
          color="success"
          :style="valid ? '' : 'background-color: #BC8989!important;'"
          @click="$emit('save')"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import MyFormRow from '@/components/MyFormRow.vue';
import { getCalendarString } from '@/util/time';

export default {
  name: 'AppointmentForm',

  props: {
    appointment: {
      type: Object,
      required: true,
    },
    colored: {
      type: Boolean,
      required: false,
      default: false,
    },
    tile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    MyFormRow,
  },

  data: () => ({
    valid: false,
    dayMenu: false,
    startMenu: false,
    endMenu: false,
    startTime: null,
    endTime: null,
    colorMenu: false,
    rules: {
      required: (value) => !!value || 'Pflicht.',
      max: (value) => (value && value.length <= 255) || 'Max. 255 Zeichen',
      min: (value) => (value && value.length >= 3) || 'Min. 3 Zeichen',
    },
  }),

  computed: {
    /**
     * Converts the start tiemstamp of an appointment to a calendar string
     * @returns {*|string} calendar string
     */
    computedDateFormattedMomentjs() {
      const date = new Date(this.appointment.start * 1000);
      return this.appointment.start ? getCalendarString(this.$moment(date)) : '';
    },
    /**
     * Converts the start timestamp of an appointment to a iso date string
     * @returns {*} iso date string
     */
    isodate() {
      const date = new Date(this.appointment.start * 1000);
      return this.$moment(date).format('YYYY-MM-DD');
    },
    /**
     * Converts the start timestamp of an appointment to HH:mm format
     * @returns {*} converted time string
     */
    getAppointmentStartHour() {
      const date = new Date(this.appointment.start * 1000);
      return this.$moment(date).format('HH:mm');
    },
    /**
     * Converts the end timestamp of an appointment to HH:mm format
     * @returns {*} converted time string
     */
    getAppointmentEndHour() {
      const date = new Date(this.appointment.end * 1000);
      return this.$moment(date).format('HH:mm');
    },
  },

  methods: {
    /**
     * Sets the timestamp of the new appointment with a default length of a hour
     * @param value {unixtimestamp|dateString} start timestamp of the appointment
     */
    setAppointmentDate(value) {
      const date = new Date(value);
      this.appointment.start = date.getTime() / 1000;
      this.appointment.end = this.appointment.start + 60 * 60;
    },
    /**
     * Sets the start time the new appointment
     * @param value {unixtimestamp|dateString} start timestamp
     */
    setAppointmentStart(value) {
      const date = new Date(this.appointment.start * 1000);
      const [hour, minutes] = value.split(':');
      date.setHours(hour, minutes);
      this.appointment.start = date.getTime() / 1000;
      if (this.appointment.start > this.appointment.end) {
        this.appointment.end = this.appointment.start;
      }
    },
    /**
     * Sets the end time the new appointment
     * @param value {unixtimestamp|dateString} end timestamp
     */
    setAppointmentEnd(value) {
      const date = new Date(this.appointment.end * 1000);
      const [hour, minutes] = value.split(':');
      date.setHours(hour, minutes);
      this.appointment.end = date.getTime() / 1000;
    },
  },

};
</script>

<style scoped>

</style>
