class Activity {
  /**
   * Represents an Activity
   * @param {number} id
   * @param {string|ActivityType} type - Type of the activity
   * @param {Date} start - Timestamp when the activity begins
   * @param {Date} end - Timestamp when the activity ends
   * @param {object} data - Additional activity data
   */
  constructor(id, type, start, end, data) {
    this.id = id;
    this.type = type;
    this.start = start;
    this.end = end;
    this.data = data || {};
  }
}

export default Activity;
