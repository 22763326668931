<template>

    <v-dialog
            transition="dialog-bottom-transition"
            max-width="800px"
            persistent
            v-model="showDialog">
        <v-card>
            <v-toolbar
                    dark
                    color="primary"
            >
                <v-spacer></v-spacer>
                <v-toolbar-title>Zusammenfassung</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="black--text">
                <v-row class="text-center ma-5">
                    <v-col>
                        <h1 class="display-2">{{stopwatchString}}</h1>
                        <h3>Dauer</h3>
                    </v-col>
                </v-row>
                <v-row class="text-center ma-5">
                    <v-col v-if="distance !== null">
                        <h1 class="display-2">{{(distance/1000).toFixed(2)}}
                            <span class="title font-weight-medium">km</span></h1>
                        <h3>Distanz</h3>
                    </v-col>
                    <v-col v-if="count !== null">
                        <h1 class="display-2">{{count}}
                            <span class="title font-weight-medium">Stück</span></h1>
                        <h3>Anzahl</h3>
                    </v-col>
                    <v-col class="grey--text">
                        <h1 class="display-2">{{kcal ? kcal : '-- '}}<span
                                class="title font-weight-medium">kcal</span></h1>
                        <h3>Kalorien</h3>
                    </v-col>
                    <v-col v-if="pace !== null">
                        <h1 class="display-2">{{pace === Infinity ? '-- ' : mmss((pace*60).toFixed(0))}}
                            <span class="title font-weight-medium">min/km</span></h1>
                        <h3>Tempo (Durchschnitt)</h3>
                    </v-col>
                </v-row>
                <v-row class="text-center ma-5">
                    <v-col>
                        <v-btn color="primary" class="mt-5" @click="$router.push('/')">
                            Fortfahren
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>
import { mmss } from '@/util/time';

export default {
  name: 'ActivitySummaryDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    distance: {
      type: Number,
      default: null,
    },
    pace: {
      type: Number,
      default: null,
    },
    kcal: {
      type: Number,
      default: null,
    },
    count: {
      type: Number,
      default: null,
    },
    stopwatchString: {
      type: String,
      default: '--:--:--',
    },
  },
  methods: {
    mmss,
  },
};
</script>

<style scoped>

</style>
