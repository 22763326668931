import { fetchAPI } from '@/util/fetch';
import Weight from '@/model/Weight';
import Activity from '@/model/Activity';
import DailyActivity from '@/model/DailyActivity';
import ManualGoal from '@/model/ManualGoal';
import Appointment from '@/model/Appointment';
import AutomatedGoal from '@/model/AutomatedGoal';
import Sleep from '@/model/Sleep';

function arrayRemove(arr, element) {
  const index = arr.indexOf(element);
  if (index > -1) {
    arr.splice(index, 1);
  }
}

class User {
  static instance = null;

  static SetToken(token) {
    localStorage.setItem('jwt', token);
  }

  static GetToken() {
    return localStorage.getItem('jwt');
  }

  static DelToken() {
    return localStorage.removeItem('jwt');
  }

  static AuthHeader() {
    return new Headers({
      Authorization: `Bearer ${User.GetToken()}`,
      'Content-Type': 'application/json',
    });
  }

  static GETOpt() {
    return { method: 'GET', headers: User.AuthHeader() };
  }

  static POSTOpt(body) {
    return { method: 'POST', headers: User.AuthHeader(), body: JSON.stringify(body) };
  }

  static PATCHOpt(body) {
    return { method: 'PATCH', headers: User.AuthHeader(), body: JSON.stringify(body) };
  }

  static DELETEOpt(body) {
    const b = body ? JSON.stringify(body) : null;
    return { method: 'DELETE', headers: User.AuthHeader(), body: b };
  }

  /**
   * User
   * @property {number} id
   * @property {string} name Name of the user
   * @property {string} sleep_start Time when the user want begin to sleep
   * @property {string} sleep_end Time when the user want to end his sleep
   * @property {number} latest_weight Latest weight measurement
   * @property {Array.<Activity>} activities All activities of the user
   * @property {Array.<Weight>} weights All measured weights of the user
   * @property {Array.<DailyActivity>} dailyactivities All daily activities of the user
   * @property {Array.<ManualGoal>} manualgoals All manual goals of the user
   * @property {Array.<AutomatedGoal>} automatedgoals All automated goals of the user
   * @property {Array.<Appointment>} appointments All appointments of the user
   */
  constructor(obj) {
    this.id = obj.id || 0;
    this.name = obj.name || '';
    this.sleep_start = obj.sleep_start || '';
    this.sleep_end = obj.sleep_end || '';
    this.latest_weight = obj.latest_weight || 0;
    this.nutrition = obj.nutrition;

    this.sleeps = [];
    this.activities = [];
    this.weights = [];
    this.dailyactivities = [];
    this.manualgoals = [];
    this.automatedgoals = [];
    this.appointments = [];
  }

  static Destroy() {
    User.instance = null;
  }

  /**
   * Gets the data about the user from backend and refreshes model
   * @param {User} instance
   */
  static async Refresh(instance) {
    /* eslint-disable no-param-reassign */
    const activities = User.GetActivities();
    const weights = User.GetWeights();
    const dailyactivities = User.GetDailyactivities();
    const manualgoals = User.GetManualgoals();
    const appointments = User.GetAppointments();
    const sleeps = User.GetSleeps();
    let user = fetchAPI('/user', User.GETOpt());

    await Promise.all([activities, weights, dailyactivities,
      manualgoals, appointments, sleeps])
      .then((values) => {
        [
          instance.activities,
          instance.weights,
          instance.dailyactivities,
          instance.manualgoals,
          instance.appointments,
          instance.sleeps,
        ] = values;
      });
    user = await user;
    instance.sleep_start = user.sleep_start;
    instance.sleep_end = user.sleep_end;
    instance.nutrition = user.nutrition;
    instance.automatedgoals = await instance.GetAutomatedGoals();
  }

  /**
   * Gets data from backend and create an instance of user
   * Singleton Pattern
   * @returns {Promise<User>}
   * @constructor
   */
  static async GetInstance() {
    if (!User.instance) {
      try {
        const res = await fetchAPI('/user', User.GETOpt());

        const instance = new User(res);
        await User.Refresh(instance);
        User.instance = instance;
      } catch (err) {
        console.error('Error initializing model:', err);
        throw err;
      }
    }
    return User.instance;
  }

  /**
   * Will send sleep settings and nutrition to backend
   * PatchUser
   */
  PatchUser() {
    const body = {
      sleep_start: this.sleep_start,
      sleep_end: this.sleep_end,
      nutrition: this.nutrition,
    };
    return fetchAPI('/user', User.PATCHOpt(body));
  }

  /**
   * DeleteUser - Deletes the user
   */
  // eslint-disable-next-line class-methods-use-this
  DeleteUser() {
    return fetchAPI('/user', User.DELETEOpt());
  }

  /**
   * Will fetch the activities from backend
   * @returns {Promise<[]>} Promise of fetch request
   * @constructor
   */
  static async GetActivities() {
    const ret = [];
    const res = await fetchAPI('/user/activity', User.GETOpt());
    res.forEach((itm) => {
      ret.push(new Activity(itm.id, itm.type, itm.start, itm.end, itm.data));
    });
    return ret;
  }

  /**
   * Will fetch all weight entries regarding the user from backend
   * @returns {Promise<[]>} Promise of fetch request
   * @constructor
   */
  static async GetWeights() {
    const ret = [];
    const res = await fetchAPI('/user/weight', User.GETOpt());
    res.forEach((itm) => {
      ret.push(new Weight(itm.timestamp, itm.value));
    });
    return ret;
  }

  /**
   * Will fetch all daily activities from backend
   * @returns {Promise<[]>} Promise of fetch request
   * @constructor
   */
  static async GetDailyactivities() {
    const ret = [];
    const res = await fetchAPI('/user/dailyactivity', User.GETOpt());
    res.forEach((itm) => {
      ret.push(new DailyActivity(itm.id, itm.name, itm.weekend, itm.icon, itm.done_timestamps));
    });
    return ret;
  }

  /**
   * Will fetch all manual goals from backend
   * @returns {Promise<[]>} Promise of fetch request
   * @constructor
   */
  static async GetManualgoals() {
    const ret = [];
    const res = await fetchAPI('/user/manualgoal', User.GETOpt());
    res.forEach((itm) => {
      ret.push(new ManualGoal(itm.id, itm.name, itm.created, itm.end, itm.icon, itm.done));
    });
    return ret;
  }

  /**
   * Will fetch all appointments of the user from backend
   * @returns {Promise<[]>} Promise of fetch request
   * @constructor
   */
  static async GetAppointments() {
    const ret = [];
    const res = await fetchAPI('/user/appointment', User.GETOpt());
    res.forEach((itm) => {
      ret.push(new Appointment(itm.id, itm.name, itm.start, itm.end, itm.color));
    });
    return ret;
  }

  /**
   * Will fetch all automated goals of the user from backend
   * @returns {Promise<[]>} Promise of fetch request
   * @constructor
   */
  async GetAutomatedGoals() {
    const ret = [];
    const res = await fetchAPI('/user/automatedgoal', User.GETOpt());
    res.forEach((itm) => {
      const daf = this.dailyactivities.filter((da) => da.id === itm.daily_activity.id);
      if (daf.length) {
        ret.push(new AutomatedGoal(itm.id, itm.name, itm.created, itm.end, daf[0], itm.icon,
          itm.quantity, itm.progress));
      } else {
        console.error('Inconsistent AutomatedGoal found: ', itm);
      }
    });
    return ret;
  }

  /**
   * Will fetch all sleep entries of the user from backend
   * @returns {Promise<[]>} Promise of fetch request
   * @constructor
   */
  static async GetSleeps() {
    const ret = [];
    const res = await fetchAPI('/user/sleep', User.GETOpt());
    res.forEach((itm) => {
      ret.push(new Sleep(itm.id, itm.start, itm.end));
    });
    return ret;
  }

  /**
   * AddActivity - Sends an activitiy entry to backend
   * @param {Activity} activity Object of the new activity
   */
  AddActivity(activity) {
    this.activities.push(activity);
    const body = {
      type: activity.type,
      start: activity.start,
      end: activity.end,
      data: activity.data,
    };
    return fetchAPI('/user/activity', User.POSTOpt(body)).then((res) => {
      User.GetActivities().then((response) => { this.activities = response; });
      return res;
    });
  }

  /**
   * AddWeight - Adds a weight measurement to the user and send it to the backend
   * @param {Weight} weight Weight measurement
   */
  AddWeight(weight) {
    this.weights.push(weight);
    const body = {
      timestamp: weight.timestamp,
      value: weight.value,
    };
    return fetchAPI('/user/weight', User.POSTOpt(body)).then((res) => {
      User.GetWeights().then((response) => { this.weights = response; });
      return res;
    });
  }

  /**
   * AddDailyactivity - Adds a daily activity to the user and send it to the backend
   * @param {DailyActivity} dailyactivity Object of the daily activity
   */
  AddDailyactivity(dailyactivity) {
    this.dailyactivities.push(dailyactivity);
    const body = {
      name: dailyactivity.name,
      weekend: dailyactivity.weekend,
      icon: dailyactivity.icon,
    };
    return fetchAPI('/user/dailyactivity', User.POSTOpt(body)).then((res) => {
      User.GetDailyactivities().then((response) => { this.dailyactivities = response; });
      return res;
    });
  }

  /**
   * AddAppointment - Adds a appointment to the user and send it to the backend
   * @param {Appointment} appointment Object of the appointment
   */
  AddAppointment(appointment) {
    this.appointments.push(appointment);
    const body = {
      name: appointment.name,
      start: appointment.start,
      end: appointment.end,
      color: appointment.color,
    };
    return fetchAPI('/user/appointment', User.POSTOpt(body)).then((res) => {
      User.GetAppointments().then((response) => { this.appointments = response; });
      return res;
    });
  }

  /**
   * AddManualGoal - Adds a manual goal to the user and send it to the backend
   * @param {ManualGoal} manualgoal Object of the manual goal
   */
  AddManualGoal(manualgoal) {
    this.manualgoals.push(manualgoal);
    const body = {
      name: manualgoal.name,
      created: manualgoal.created || new Date().getTime() / 1000,
      end: manualgoal.end,
      icon: manualgoal.icon,
    };
    return fetchAPI('/user/manualgoal', User.POSTOpt(body)).then((res) => {
      User.GetManualgoals().then((response) => { this.manualgoals = response; });
      return res;
    });
  }

  /**
   * AddAutomatedGoal - Adds a automated goal to the user and send it to the backend
   * @param {AutomatedGoal} automatedgoal Object of the automated goal
   */
  AddAutomatedGoal(automatedgoal) {
    this.automatedgoals.push(automatedgoal);
    const body = {
      name: automatedgoal.name,
      icon: automatedgoal.icon,
      created: automatedgoal.created || new Date().getTime() / 1000,
      end: automatedgoal.end,
      quantity: automatedgoal.quantity,
      daily_activity_id: automatedgoal.dailyActivity.id,
    };
    return fetchAPI('/user/automatedgoal', User.POSTOpt(body)).then((res) => {
      this.GetAutomatedGoals().then((response) => { this.automatedgoals = response; });
      return res;
    });
  }

  /**
   * AddSleep - Adds a sleep entry to the user and send it to the backend
   * @param {Sleep} sleep Object of the sleep entry
   */
  AddSleep(sleep) {
    this.sleeps.push(sleep);
    const body = {
      start: sleep.start,
      end: sleep.end,
    };
    return fetchAPI('/user/sleep', User.POSTOpt(body)).then((res) => {
      User.GetSleeps().then((response) => { this.sleeps = response; });
      return res;
    });
  }

  /**
   * PatchDailyactivity - Changes the data of a daily activity
   * @param {DailyActivity} dailyactivity Object of the daily activity
   */
  PatchDailyactivity(dailyactivity) {
    const body = {
      name: dailyactivity.name,
      weekend: dailyactivity.weekend,
      icon: dailyactivity.icon,
    };
    return fetchAPI(`/user/dailyactivity/${dailyactivity.id}`, User.PATCHOpt(body)).then((res) => {
      User.GetDailyactivities().then((response) => { this.dailyactivities = response; });
      return res;
    });
  }

  /**
   * PatchDailyactivityDone - Changes the data of a daily activity in the database
   * @param {DailyActivity} dailyactivity Object of the daily activity
   */
  PatchDailyactivityDone(dailyactivity) {
    const body = {
      timestamp: Math.max(...dailyactivity.done), // solange niemand mit Zeitmaschine ankommt ok
    };
    return fetchAPI(`/user/dailyactivity/${dailyactivity.id}/done`, User.PATCHOpt(body)).then((res) => {
      User.GetDailyactivities().then((response) => {
        this.dailyactivities = response;
        this.GetAutomatedGoals().then((ag) => {
          this.automatedgoals = ag;
        });
      });
      return res;
    });
  }

  /**
   * DeleteDailyactivityDone - Remove the done status of the daily activity
   * @param {DailyActivity} dailyactivity
   */
  DeleteDailyactivityDone(dailyactivity) {
    const body = {
      timestamp: Math.max(...dailyactivity.done), // solange niemand mit Zeitmaschine ankommt ok
    };
    return fetchAPI(`/user/dailyactivity/${dailyactivity.id}/done`, User.DELETEOpt(body))
      .then((res) => {
        User.GetDailyactivities()
          .then((response) => {
            this.dailyactivities = response;
            this.GetAutomatedGoals().then((ag) => {
              this.automatedgoals = ag;
            });
          });
        return res;
      });
  }

  /**
   * PatchAppointment - Changes the data of an appointment in the database
   * @param {Appointment} appointment
   */
  PatchAppointment(appointment) {
    const body = {
      name: appointment.name,
      start: appointment.start,
      end: appointment.end,
      color: appointment.color,
    };
    return fetchAPI(`/user/appointment/${appointment.id}`, User.PATCHOpt(body)).then((res) => {
      User.GetAppointments().then((response) => { this.appointments = response; });
      return res;
    });
  }

  /**
   * PatchManualGoal - Changes the data of a manual goal in the database
   * @param {ManualGoal} manualgoal
   */
  PatchManualGoal(manualgoal) {
    const body = {
      name: manualgoal.name,
      created: manualgoal.created || new Date().getTime() / 1000,
      end: manualgoal.end,
      icon: manualgoal.icon,
      done: manualgoal.done,
    };
    return fetchAPI(`/user/manualgoal/${manualgoal.id}`, User.PATCHOpt(body)).then((res) => {
      User.GetManualgoals().then((response) => { this.manualgoals = response; });
      return res;
    });
  }

  /**
   * PatchAutomatedGoal - Changes the data of an automated goal in the database
   * @param {AutomatedGoal} automatedgoal
   */
  PatchAutomatedGoal(automatedgoal) {
    const body = {
      name: automatedgoal.name,
      icon: automatedgoal.icon,
      created: automatedgoal.created || new Date().getTime() / 1000,
      end: automatedgoal.end,
      quantity: automatedgoal.quantity,
      daily_activity_id: automatedgoal.dailyActivity.id,
    };
    return fetchAPI(`/user/automatedgoal/${automatedgoal.id}`, User.PATCHOpt(body)).then((res) => {
      this.GetAutomatedGoals().then((response) => { this.automatedgoals = response; });
      return res;
    });
  }

  /**
   * PatchSleep - Changes the data of a sleep entry in the database
   * @param {Sleep} sleep
   */
  PatchSleep(sleep) {
    const body = {
      start: sleep.start,
      end: sleep.end,
    };
    return fetchAPI(`/user/sleep/${sleep.id}`, User.PATCHOpt(body)).then((res) => {
      User.GetSleeps().then((response) => { this.sleeps = response; });
      return res;
    });
  }

  /**
   * DeleteActivity - Changes the data of an appointment and removes it from database
   * @param {Activity} activity
   */
  DeleteActivity(activity) {
    arrayRemove(this.activities, activity);
    return fetchAPI(`/user/activity/${activity.id}`, User.DELETEOpt()).then((res) => {
      User.GetActivities().then((response) => { this.activities = response; });
      return res;
    });
  }

  /**
   * DeleteWeight - Deletes a weight measurement and removes it from database
   * @param {Weight} weight
   */
  DeleteWeight(weight) {
    arrayRemove(this.weights, weight);
    return fetchAPI(`/user/weight/${weight.timestamp}`, User.DELETEOpt()).then((res) => {
      User.GetWeights().then((response) => { this.weights = response; });
      return res;
    });
  }

  /**
   * DeleteDailyactivity - Deletes a daily activity and removes it from database
   * @param {DailyActivity} dailyactivity
   */
  DeleteDailyactivity(dailyactivity) {
    arrayRemove(this.dailyactivities, dailyactivity);
    return fetchAPI(`/user/dailyactivity/${dailyactivity.id}`, User.DELETEOpt()).then((res) => {
      User.GetDailyactivities().then((response) => { this.dailyactivities = response; });
      return res;
    });
  }

  /**
   * DeleteManualgoal - Deletes a manual goal and removes it from database
   * @param {ManualGoal} manualgoal
   */
  DeleteManualgoal(manualgoal) {
    arrayRemove(this.manualgoals, manualgoal);
    return fetchAPI(`/user/manualgoal/${manualgoal.id}`, User.DELETEOpt()).then((res) => {
      User.GetManualgoals().then((response) => { this.manualgoals = response; });
      return res;
    });
  }

  /**
   * DeleteAppointment - Deletes an appointment and removes it from database
   * @param {Appointment} appointment
   */
  DeleteAppointment(appointment) {
    arrayRemove(this.appointments, appointment);
    return fetchAPI(`/user/appointment/${appointment.id}`, User.DELETEOpt()).then((res) => {
      User.GetAppointments().then((response) => { this.appointments = response; });
      return res;
    });
  }

  /**
   * DeleteAutomatedGoal - Deletes an automated goal and removes it from database
   * @param {AutomatedGoal} automatedgoal
   */
  DeleteAutomatedGoal(automatedgoal) {
    arrayRemove(this.automatedgoals, automatedgoal);
    return fetchAPI(`/user/automatedgoal/${automatedgoal.id}`, User.DELETEOpt()).then((res) => {
      this.GetAutomatedGoals().then((response) => { this.automatedgoals = response; });
      return res;
    });
  }

  /**
   * DeleteSleep - Deletes a sleep entry and removes it from database
   * @param {Sleep} sleep
   */
  DeleteSleep(sleep) {
    arrayRemove(this.sleeps, sleep);
    return fetchAPI(`/user/sleep/${sleep.id}`, User.DELETEOpt()).then((res) => {
      User.GetSleeps().then((response) => { this.sleeps = response; });
      return res;
    });
  }
}

export default User;
