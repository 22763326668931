<template>
  <v-bottom-navigation
    app
    v-model="selectedItem"
    grow
    class="ma-0 pa-0"
    height="75"
    color="white"
    background-color="primary"
    style="overflow: auto;"
  >
    <div class="scroll">
      <v-btn value="/dashboard" to="/dashboard">
        <span>Dashboard</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>

      <v-btn value="/calendar" to="/calendar">
        <span>Terminplan</span>

        <v-icon>mdi-calendar</v-icon>
      </v-btn>

      <v-btn value="/goals" to="/goals">
        <span>Ziele</span>

        <v-icon>mdi-flag-checkered</v-icon>
      </v-btn>

      <v-btn value="/activity/new" color="orange" class="activityButton" to="/activity/new">
        <span style="color: white" class="text-center ma-0 pa-0">Aktivität<br>starten</span>

        <v-icon color="white" class="ma-0 pa-0">mdi-plus</v-icon>
      </v-btn>

      <v-btn value="/history" to="/history">
        <span>Aktivitäten</span>

        <v-icon>mdi-history</v-icon>
      </v-btn>

      <v-btn value="/profile" to="/profile">
        <span>Profil</span>

        <v-icon>mdi-account</v-icon>
      </v-btn>

      <v-btn value="/settings" to="/settings">
        <span>Optionen</span>

        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </div>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'BottomNavigation',
  data() {
    return {
      selectedItem: this.$route.path,
    };
  },
};
</script>

<style scoped>
.activityButton{
  border-radius: 40px !important;
  min-height: 100%;
  font-size: 90%!important;
}
.scroll {
  align-items: center;
  display: inline-flex;
  flex-wrap: nowrap;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 0;
  margin: 0;
}
.v-item-group.v-bottom-navigation .v-btn {
  font-size: 1.0em;
  min-width: 107px;
  background: #00B5B5;
}
</style>
