<template>
    <v-container>
        <PageTitle title="Anmelden"></PageTitle>
        <v-row style="margin-top: 10vh"
                align="center"
               justify="center">
                <v-card color="primary" >
                  <v-form v-model="valid">
                    <v-card-text c>
                        <table>
                            <tr>
                                <td class="title">Username:</td>
                                <td>
                                    <v-text-field
                                      class="inputfield"
                                      v-model="name"
                                      :rules="[rules.required, rules.max]"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td class="title">Passwort:</td>
                                <td>
                                    <v-text-field
                                      class="inputfield"
                                      type="password"
                                      v-model="password"
                                      :rules="[rules.required, rules.max, rules.minpw]"
                                    />
                                </td>
                            </tr>
                        </table>
                        <router-link
                            to="/register"
                            tag="a">
                            Noch kein Konto? <b>Dann erstelle eins hier!</b>
                        </router-link>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn
                          color="secondary"
                          @click="login"
                          :loading="loading"
                          :disabled="!valid"
                        >
                          Anmelden
                        </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
        </v-row>
    </v-container>
</template>

<script>
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import User from '@/model/User';
import PageTitle from '@/components/PageTitle.vue';
import { fetchAPI } from '@/util/fetch';
import EventBus from '@/util/eventBus';
import { handle } from '@/subscribe';

export default {
  name: 'Anmelden',
  components: {
    PageTitle,
  },
  data() {
    return {
      loading: false,
      valid: false,
      name: '',
      password: '',
      header: new Headers({
        'Content-Type': 'application/json',
      }),
      // rules for username/password
      rules: {
        required: (value) => !!value || 'Pflicht.',
        max: (value) => value.length <= 255 || 'Max. 255 Zeichen',
        minpw: (value) => value.length >= 8 || 'Min. 8 Zeichen',
      },
    };
  },

  created() {
    // if we accidentally get here while logged in -> go to dashboard
    const token = User.GetToken();
    if (this.checkToken(token)) {
      this.$router.push('/dashboard');
    }
  },

  methods: {
    /**
     * Check whether JWT is still valid
     * @param token JWP token
     * @return {boolean} expired?
     */
    checkToken(token) {
      if (token) {
        const decoded = jwt_decode(token);
        if (Date.now() < decoded.exp * 1000) {
          return true;
        }
      }
      return false;
    },
    /**
     * Log the user in
     * also subscribe to UserAgent(PushNotification)
     */
    login() {
      this.loading = true;
      const body = JSON.stringify({
        name: this.name,
        password: this.password,
      });

      fetchAPI('/login', { method: 'POST', headers: this.header, body }).then((res) => {
        if (this.checkToken(res.token)) {
          User.SetToken(res.token);
          EventBus.$emit('user-login');
        } else {
          throw new Error(res.errors);
        }

        // Subscribe user for PushNotifications
        if ('PushManager' in window) {
          navigator.serviceWorker.ready
            .then((worker) => {
              handle(worker, User.GetToken());
            });
        }
      }).catch((e) => {
        const res = typeof e === 'string' ? JSON.parse(e) : e;
        let text = `Unbekannter Fehler beim login: ${e}`;
        if (res.message === 'Invalid credentials') {
          text = 'Fehler beim login: Benutzername oder Passwort falsch';
        }
        EventBus.$emit('new-snackbar', text, 'error', 5000, true);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
    .inputfield {
        width: 250px;
    }

    th, td {
        padding: 5px;
    }

    .inputfield >>> .v-text-field__slot input {
        background-color: white
    }
</style>
