/**
 *  Angelehnt an https://developers.google.com/web/fundamentals/push-notifications/subscribing-a-user
*/

import urlBase64ToUint8Array from '@/util/urlBase64ToUint8Array';

/**
 * Ask user for permission to show notifications, resolves if already allowed
 * @return {Promise<void>} Promise whether allowed or other options
 */
function askPermission() {
  return new Promise(((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }))
    .then((permissionResult) => {
      if (permissionResult !== 'granted') {
        throw new Error('We weren\'t granted permission.');
      }
    });
}

/**
 * Gets the public key from the server
 * @return {Promise<any>} Promise with json object, containing the public_key
 */
function getPublicKey() {
  return fetch(`${process.env.VUE_APP_BACKEND_URL}/subscription/`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Bad status code from server.');
      }

      return response.json();
    })
    .then((responseData) => {
      if (!responseData.public_key) {
        throw new Error(`Got bad public key: ${responseData}`);
      }

      return responseData.public_key;
    });
}

/**
 * Subscribes a user to the push service
 * @param registration ServiceWorkerRegistration object
 * @param pkey public key string
 * @return {Promise<PushSubscription>} PushSubscription object
 */
function subscribeUserToPush(registration, pkey) {
  const subscribeOptions = {
    userVisibleOnly: true,
    // Public key
    applicationServerKey: urlBase64ToUint8Array(
      pkey,
    ),
  };

  return registration.pushManager.subscribe(subscribeOptions);
}

/**
 * Send the subscription to the backend
 * @param subscription subscription object
 * @param token jwt token from user, for identification
 */
function sendSubscriptionToBackEnd(subscription, token) {
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      // eslint-disable-next-line max-len
      Authorization: `Bearer: ${token}`,
    },
    body: JSON.stringify(subscription),
  };
  fetch(`${process.env.VUE_APP_BACKEND_URL}/subscription/`, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Bad status code from server.');
      }

      return response.json();
    })
    .then((responseData) => {
      if (!(responseData.status === 'ok')) {
        throw new Error('Bad response from server.');
      }
    });
}

/**
 * Handles push registration with user agent and backend
 * @param registration ServiceWorkerRegistration
 * @param token jwt token from user, for identification
 */
function handle(registration, token) {
  askPermission()
    .then(() => {
      console.log('Got permissions');
      getPublicKey()
        .then((pkey) => {
          console.log('PKEY: ', pkey);
          subscribeUserToPush(registration, pkey)
            .then((pushSubscription) => {
              console.log('Received PushSubscription: ', pushSubscription);
              sendSubscriptionToBackEnd(pushSubscription, token);
            })
            .catch((err) => console.warn(err));
        });
    })
    .catch(() => {
      console.warn('No notification permission');
    });

  registration.addEventListener('pushsubscriptionchange', (event) => {
    event.waitUntil(
      Promise.all([
        subscribeUserToPush(registration),
        sendSubscriptionToBackEnd(registration),
      ]),
    );
  });
}

/**
 * Deletes the current subscription from the service worker registration instance
 * @param registration ServiceWorkerRegistration
 */
// eslint-disable-next-line no-unused-vars
function deleteSubscription(registration) {
  if (registration.pushManager) {
    registration.pushManager.getSubscription()
      .then((subscription) => subscription.unsubscribe()
        .then((msg) => {
          if (msg) console.log('Unsubscribed successfully!');
        }));
  }
}

export { handle, deleteSubscription };
