/**
 * Wrapper for js fetch function with timeout
 * @param {string} url url
 * @param {object} opt options
 * @param {number} timeout time when to reject in ms
 * @return Promise fetched result, or Error
 */
export function fetchWithTimeout(url, opt, timeout) {
  return new Promise((resolve, reject) => {
    // Set timeout timer
    const timer = setTimeout(
      () => reject(new Error(`Request timed out with ${timeout}`)),
      timeout,
    );

    fetch(url, opt).then(
      (response) => resolve(response),
      (err) => reject(err),
    ).finally(() => clearTimeout(timer));
  });
}

/**
 * Wrapper for fetchWithTimeout that prepends the Backend url and uses the configured timeout
 * @param {string} resource url
 * @param {object} opt options
 * @return Promise fetched result, or Error
 */
export function fetchAPI(resource, opt) {
  return new Promise((resolve, reject) => {
    fetchWithTimeout(process.env.VUE_APP_BACKEND_URL + resource, opt, process.env.VUE_APP_FETCH_TIMEOUT)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 'error') {
          reject(JSON.stringify(res.errors));
        } else {
          resolve(res);
        }
      }).catch((e) => reject(e));
  });
}

export default fetchAPI;
