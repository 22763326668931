/* eslint-disable no-console */

import { register } from 'register-service-worker';

/**
 * Registers a serviceworker and offers some hooks
 */
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    /**
     * If new ServiceWorker is available and user agrees to update, inform ServiceWorker
     * @param registration ServiceWorkerRegistration
     */
    updated(registration) {
      console.log('New content is available; please refresh.');
      if (registration.waiting) {
        // eslint-disable-next-line no-alert
        if (window.confirm('Es steht ein Update bereit. Jetzt aktualisieren?\nDies wird die Seite neu laden.')) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          window.location.reload();
        }
      }
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
