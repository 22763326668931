/**
 * Converts a number to a two digit number. It fills the number with a null
 * if it is one digit long.
 * Example: 5 -> 05
 * @param num Number which should be converted
 * @returns {string} Converted two digit number
 */
function pad(num) {
  return (`0${num}`).slice(-2);
}

/**
 * Converts an amount of seconds to a time string with format: HH:MM:SS
 * Example: 01:23:45
 * @param seconds Amount of seconds which should be converted
 * @returns {string}
 */
export function hhmmss(seconds) {
  let minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}

/**
 * Converts an amount of seconds to a time string with format: MM:SS
 * Example: 01:23
 * @param seconds Amount of seconds which should be converted
 * @returns {string}
 */
export function mmss(seconds) {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${pad(minutes)}:${pad(secs)}`;
}

/**
 * Converts a moment.js date object to a date string as a word or a date like Today, Tomorrow,
 * Yesterday, Last Friday, Friday or the date itself if it isn't in the next or last seven days.
 * @param momentDate Date object of moment.js
 * @returns {*} Date string
 */
export function getCalendarString(momentDate) {
  return momentDate.calendar(null, {
    lastDay: '[Gestern]',
    sameDay: '[Heute]',
    nextDay: '[Morgen]',
    lastWeek: '[letzten] dddd',
    nextWeek: 'dddd',
    sameElse: 'L',
  });
}
