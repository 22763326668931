<template>
  <v-container v-if="user">
    <PageTitle title="Profil"></PageTitle>
    <!-- Gewicht Card-->
    <v-card class="pa-5" flat elevation="5">
      <v-card-title class="justify-center display-2">Gewicht</v-card-title>
      <LineChart
        ref="weightChart"
        :chartdata="weightGraphData"
        :options="weightGraphOptions"
        style="height: 400px; width: 100%"
      />
      <v-row class="justify-center mt-5">
        <div class="text-h5">Neues Gewicht (kg) eintragen:</div>
      </v-row>
      <v-row class="mt-4 justify-center">
        <v-col cols="6">
          <v-text-field
            filled
            type="number"
            v-bind:value="weightInstance.value"
            @input="(val) => weightInstance.value = val ? parseFloat(val) : null"
          >
            <template v-slot:append-outer>
              <v-btn
                icon
                @click="addWeight"
                :disabled="!weightInstance.value"
              >
                <v-icon large>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="weightHeaders"
        :items="weights_sorted"
      >
        <!-- define item action -->
        <template v-slot:item.date="{ item }">
          {{ $moment(item.timestamp * 1000).format('DD.MM.YYYY') }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="deleteWeight(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Schlaf Card-->
    <v-card class="mt-12 pa-5" flat elevation="5">
      <v-card-title class="justify-center display-2">Schlaf</v-card-title>
      <LineChart
        ref="sleepChart"
        :chartdata="sleepGraphData"
        :options="sleepGraphOptions"
        style="height: 400px; width: 100%"
      />
      <MyFormRow
        title="Schlafrythmus"
        :col="false"
      >
        <v-col
          :cols="$vuetify.breakpoint.xsOnly? 12 : null"
        >
          <v-menu
            ref="menuStart"
            v-model="startMenu"
            :close-on-content-click="false"
            :return-value.sync="sleepInstance.start"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="sleepInstance.start"
                label="Startzeit"
                full-width
                prepend-inner-icon="mdi-clock-time-two-outline"
                readonly
                clearable
                filled
                v-bind="attrs"
                v-on="on"
                m
              />
            </template>
            <v-time-picker
              v-if="startMenu"
              v-model="sleepInstance.start"
              format="24hr"
              full-width
              @click:minute="$refs.menuStart.save(sleepInstance.start)"
            />
          </v-menu>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.xsOnly? 12 : null"
        >
          <v-menu
            ref="menuEnd"
            v-model="endMenu"
            :close-on-content-click="false"
            :return-value.sync="sleepInstance.end"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="sleepInstance.end"
                label="Endzeit"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                clearable
                filled
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-time-picker
              v-if="endMenu"
              v-model="sleepInstance.end"
              format="24hr"
              full-width
              @click:minute="$refs.menuEnd.save(sleepInstance.end)"
            />
          </v-menu>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.xsOnly? 12 : null"
        >
          <v-btn
            icon
            class="mt-3"
            @click="addSleep"
            :disabled="!sleepInstance.end || !sleepInstance.start"
          >
            <v-icon large>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </MyFormRow>
      <v-data-table
        :headers="sleepHeaders"
        :items="sleeps_sorted"
        locale="de-DE"
      >
        <template v-slot:item.start="{ item }">
          {{ $moment(item.start * 1000).format('HH:mm') }}
        </template>
        <template v-slot:item.end="{ item }">
          {{ $moment(item.end * 1000).format('HH:mm') }}
        </template>
        <template v-slot:item.duration="{ item }">
          {{ $moment.duration($moment(item.end * 1000).diff($moment(item.start * 1000))).format('HH:mm') }}
        </template>
        <!-- define item action -->
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="deleteSleep(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import MyFormRow from '@/components/MyFormRow.vue';
import PageTitle from '@/components/PageTitle.vue';
import LineChart from '@/components/LineChart.vue';
import User from '@/model/User';
import Weight from '@/model/Weight';
import Sleep from '@/model/Sleep';
import EventBus from '@/util/eventBus';

export default {
  name: 'Profile',

  components: {
    LineChart,
    MyFormRow,
    PageTitle,
  },

  data() {
    return {
      user: null,
      now: this.$moment(),

      weightInstance: new Weight(Math.round(new Date().getTime() / 1000), null),
      weightHeaders: [
        { text: 'Datum', value: 'date', sortable: false },
        { text: 'Gewicht (kg)', value: 'value', sortable: false },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ],
      // options for weight graph
      weightGraphOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 50,
              suggestedMax: 106,
            },
          }],
          xAxes: [{
            type: 'time',
            time: {
              parser: 'YYYY-MM-DD HH:mm:ss',
              unit: 'day',
              displayFormats: {
                day: 'DD.MM.YY',
              },
            },
            distribution: 'linear',
            bounds: 'ticks',
            ticks: {
              source: 'data',
            },
          }],
        },
        tooltips: {
          callbacks: {
            label: (item) => `${item.yLabel}kg`,
          },
        },
      },

      startMenu: false,
      endMenu: false,
      sleepInstance: new Sleep(null, null, null),
      sleepHeaders: [
        { text: 'Von', value: 'start', sortable: false },
        { text: 'Bis', value: 'end', sortable: false },
        { text: 'Dauer', value: 'duration', sortable: false },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ],
      // options for sleep graph
      sleepGraphOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 4,
              suggestedMax: 12,
            },
          }],
          xAxes: [{
            type: 'time',
            time: {
              parser: 'YYYY-MM-DD HH:mm:ss',
              unit: 'day',
              displayFormats: {
                day: 'DD.MM.YY',
              },
            },
            distribution: 'linear',
            bounds: 'ticks',
            ticks: {
              source: 'data',
            },
          }],
        },
        tooltips: {
          callbacks: {
            label: (item) => `${item.yLabel} Stunden`,
          },
        },
      },
    };
  },

  mounted() {
    User.GetInstance()
      .then((usr) => {
        this.user = usr;
      })
      .catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });
  },

  computed: {
    /**
     * Sorts the weight entry list by thier timestamp
     * @returns {array} sorted list
     */
    weights_sorted() {
      return [...this.user.weights].sort((a, b) => b.timestamp - a.timestamp);
    },
    /**
     * Sorts the sleep entry list by thier start time
     * @returns {array} sorted list
     */
    sleeps_sorted() {
      return [...this.user.sleeps].sort((a, b) => b.start - a.start);
    },
    /**
     * Returns the necessary data to display the weight graph
     * @returns {{datasets: [{
     *   backgroundColor: default.computed.$vuetify.theme.themes.light.secondary,
     *   data: [], label: string, lineTension: number}]}} Graphdata
     */
    weightGraphData() {
      const data = [];
      const lastWeek = this.now.clone().subtract(7, 'days').startOf('day');

      // eslint-disable-next-line max-len
      const latestWeights = this.user.weights.filter((weight) => this.$moment(weight.timestamp * 1000).isAfter(lastWeek)).sort((a, b) => a.timestamp - b.timestamp);
      // eslint-disable-next-line max-len
      latestWeights.forEach((weight) => data.push({ t: new Date(weight.timestamp * 1000), y: weight.value }));

      return {
        datasets: [
          {
            label: 'Gewicht',
            backgroundColor: this.$vuetify.theme.themes.light.secondary,
            lineTension: 0,
            data,
          },
        ],
      };
    },
    /**
     * Returns the necessary data to display the weight graph
     * @returns {{datasets: [{
     *   backgroundColor: default.computed.$vuetify.theme.themes.light.secondary,
     *   data: [], label: string, lineTension: number}]}} Graphdata
     */
    sleepGraphData() {
      const data = [];
      const lastWeek = this.now.clone().subtract(7, 'days').startOf('day');

      // eslint-disable-next-line max-len
      const latestSleeps = this.user.sleeps.filter((sleep) => this.$moment(sleep.start * 1000).isAfter(lastWeek)).sort((a, b) => a.timestamp - b.timestamp);
      // eslint-disable-next-line max-len
      latestSleeps.forEach((sleep) => data.push({
        t: new Date(sleep.start * 1000),
        y: ((sleep.end - sleep.start) / (60 * 60)).toPrecision(2),
      }));

      return {
        datasets: [
          {
            label: 'Schlaf',
            backgroundColor: this.$vuetify.theme.themes.light.secondary,
            lineTension: 0,
            data,
          },
        ],
      };
    },
  },

  methods: {
    /**
     * Adds a weight measurement
     */
    addWeight() {
      this.user.AddWeight(this.weightInstance)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
      this.weightInstance = new Weight(Math.round(new Date().getTime() / 1000), null);
      this.$refs.weightChart.renderChart(this.weightGraphData, this.weightGraphOptions);
    },
    /**
     * Deletes a weight measurement
     * @param item Weight measurement that should be deleted
     */
    deleteWeight(item) {
      this.user.DeleteWeight(item)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
      this.$refs.weightChart.renderChart(this.weightGraphData, this.weightGraphOptions);
    },
    /**
     * Adds a sleep measurement
     */
    addSleep() {
      const start = this.sleepInstance.start.split(':');
      const end = this.sleepInstance.end.split(':');

      this.sleepInstance.start = this.$moment().startOf('date').hour(start[0]).minutes(start[1]);
      this.sleepInstance.end = this.$moment().startOf('date').hour(end[0]).minutes(end[1]);

      if (this.sleepInstance.start > this.sleepInstance.end) {
        this.sleepInstance.start = this.sleepInstance.start.subtract(1, 'day');
      }

      this.sleepInstance.start = this.sleepInstance.start.unix();
      this.sleepInstance.end = this.sleepInstance.end.unix();

      this.user.AddSleep(this.sleepInstance)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
      this.sleepInstance = new Sleep(null, null, null);

      this.$refs.sleepChart.renderChart(this.sleepGraphData, this.sleepGraphOptions);
    },
    /**
     * Deletes a sleep measurement
     * @param item Sleep measurement that should be deleted
     */
    deleteSleep(item) {
      this.user.DeleteSleep(item)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
      this.$refs.sleepChart.renderChart(this.sleepGraphData, this.sleepGraphOptions);
    },
  },
};
</script>
