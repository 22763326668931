var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('PageTitle',{attrs:{"title":"Aktivitätsverlauf"}}),_c('div',[_c('MyFormRow',{attrs:{"title":"Typ"}},[_c('v-select',{staticClass:"mt-n4",attrs:{"items":_vm.activityTypes,"clearable":"","value":"text","filled":"","return-object":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('MyFormRow',{attrs:{"title":"Zeitraum","col":false}},[_c('v-col',{staticClass:"mt-n4",attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly? 12 : null}},[_c('v-menu',{ref:"menuStart",attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.startTime ? _vm.$moment(_vm.startTime).format('DD.MM.YYYY') : '',"label":"Startdatum","full-width":"","prepend-inner-icon":"mdi-calendar","append-outer-icon":"mdi-close","readonly":"","filled":""},on:{"click:append-outer":function($event){_vm.startTime = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[(_vm.startMenu)?_c('v-date-picker',{attrs:{"format":"24hr","locale":"de-DE","full-width":"","max":_vm.endTime},on:{"click:minute":function($event){return _vm.$refs.menuStart.save(_vm.startTime)}},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}):_vm._e()],1)],1),_c('v-col',{staticClass:"mt-n4",attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly? 12 : null}},[_c('v-menu',{ref:"menuEnd",attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.endTime ? _vm.$moment(_vm.endTime).format('DD.MM.YYYY') : '',"label":"Enddatum","full-width":"","prepend-inner-icon":"mdi-calendar","append-outer-icon":"mdi-close","readonly":"","filled":""},on:{"click:append-outer":function($event){_vm.endTime = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endMenu),callback:function ($$v) {_vm.endMenu=$$v},expression:"endMenu"}},[(_vm.endMenu)?_c('v-date-picker',{attrs:{"format":"24hr","locale":"de-DE","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuEnd.save(_vm.endTime)}},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}}):_vm._e()],1)],1)],1)],1),_c('v-data-table',{staticClass:"mt-8",attrs:{"headers":_vm.headers,"items":_vm.history,"locale":"de-DE"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('span',{staticClass:"text-h5"},[_vm._v("Aktivitäten")])]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type_parsed ? item.type_parsed.text : '')+" ")]}},{key:"item.cal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.calories ? item.data.calories : '')+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.hhmmss(item.end - item.start))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.start * 1000).format('DD.MM.YYYY - HH:MM[Uhr]'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"#ccc"}},[_vm._v(" mdi-magnify ")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteActivity(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }