class ManualGoal {
  /**
   * ManualGoal
   * @param {number} id
   * @param {string} name Title of the goal
   * @param {Date} created Date when the goal started
   * @param {Date} end Date when the goal ends
   * @param {string} icon Shown icon of the goal
   * @param {boolean} done Tells if the activity is done
   */
  constructor(id, name, created, end, icon, done) {
    this.id = id;
    this.name = name;
    this.created = created;
    this.end = end;
    this.icon = icon || null;
    this.done = done || false;
  }
}

export default ManualGoal;
