<template>
  <v-container>
    <PageTitle title="Aktivität beginnen"></PageTitle>
    <v-container>
      <h1 class="display-2">Wähle eine Aktivität aus:</h1>
      <v-row class="mt-4">
        <v-col class="text-center" v-for="activity in activities" v-bind:key="activity.type"
               cols="12" sm="6" lg="4" xl="2">
          <v-card class="pa-6  selectableActivity" @click="selectActivity(activity)">
            <img :src="require('../assets/icons/' + (activity.icon || 'no.png'))" class="sportIcon">
            <h2>{{activity.text}}</h2>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { ActivityTypeTranslate } from '@/model/ActivityType';
import PageTitle from '../components/PageTitle.vue';

export default {
  name: 'NewActivity',
  components: {
    PageTitle,
  },
  data() {
    return {
      activities: ActivityTypeTranslate,
    };
  },
  methods: {
    /**
     * Set the new activity in local storage
     * @param activity activity
     */
    selectActivity(activity) {
      localStorage.setItem('currentActivity', JSON.stringify(activity));
      this.$router.push('/activity');
    },
  },
};
</script>

<style scoped>
  .sportIcon{
    height: 100px;
  }

  .selectableActivity{
    border-radius: 15px;
    cursor: pointer;
  }
  .selectableActivity:hover{
    transform: scale(1.05);
    background-color: #C7FFAD;
    border: 4px solid black;
    transition: transform 0.3s, background-color 0.5s, border 0.1s;
  }
</style>
