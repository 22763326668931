<template>
    <v-app-bar
      app
      color="primary"
      v-if="$vuetify.breakpoint.smAndUp"
    >

      <v-toolbar-title class="white--text">
        Personal Best
      </v-toolbar-title>

    </v-app-bar>
</template>

<script>

export default {
  name: 'HeaderMenu',

  computed: {
  },

  data: () => ({
    showDrawer: false,
  }),
};
</script>

<style scoped>

</style>
