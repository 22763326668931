<template>
  <v-container v-if="user">
    <PageTitle title="Ziele"></PageTitle>

    <v-card color="primary" class="pa-5" >
      <v-row>
        <v-col cols="10">
          <h3 class="white--text headline">Bestehende Ziele</h3>
        </v-col>
        <v-col cols="2">
          <v-btn
            class="pa-0"
            color="secondary"
            @click="post"
            block
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <goal-entry
        v-for="mg in manualgoals"
        v-bind:key="'mg' + mg.id"
        :goal="mg"
        @change="doneManualGoal"
        @patch-goal="patch"
        @delete-goal="del"
      />

      <div class="py-2">
        <v-divider/>
      </div>

      <goal-entry
        v-for="ag in automatedgoals"
        v-bind:key="'ag' + ag.id"
        :goal="ag"
        @patch-goal="patch"
        @delete-goal="del"
      />

    </v-card>

    <!-- AddItemDialog -->
    <v-dialog
      v-model="addItemDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            Ziel {{ itemForm.creating ? 'hinzufügen' : 'bearbeiten'}}
          </span>
        </v-card-title>

        <v-card-text>
          <v-container v-if="itemForm.instance">
            <v-form v-model="itemForm.valid" ref="form">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Name des Ziels"
                    :rules="[(v) => v && v.length >= 3 || 'Name zu kurz']"
                    v-model="itemForm.instance.name"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <MyIconPicker v-model="itemForm.instance.icon"/>
                </v-col>
              </v-row>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind:value="$moment(itemForm.instance.end * 1000).format('DD.MM.YYYY')"
                    label="Fälligkeitsdatum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-bind:value="$moment(itemForm.instance.end * 1000).format('YYYY-MM-DD')"
                  @input="(v) => itemForm.instance.end = new Date(v).getTime() / 1000"
                  no-title
                  scrollable
                  :min="$moment().add(1,'days').format('YYYY-MM-DD')"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    locale="de-DE"
                    @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-row>
                <v-col>
                  <MyDailyActivityPicker
                    :items="user.dailyactivities"
                    v-model="itemForm.instance.dailyActivity"
                    :rules="[(v) => v || !v]"
                    :disabled="!itemForm.automatedgoal"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Anzahl"
                    v-bind:value="itemForm.instance.quantity"
                    @input="(val) => itemForm.instance.quantity = val ? parseInt(val) : null"
                    clearable
                    type="number"
                    :disabled="!itemForm.instance.dailyActivity || !itemForm.automatedgoal"
                    :rules="[(v) => !itemForm.instance.dailyActivity || (v && v >= 1) || 'muss ausgefüllt sein']"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="addItemDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!itemForm.valid"
            @click="itemForm.creating ? saveNewItem() : saveOldItem()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import GoalEntry from '@/components/GoalEntry.vue';
import PageTitle from '@/components/PageTitle.vue';
import MyIconPicker from '@/components/MyIconPicker.vue';
import MyDailyActivityPicker from '@/components/MyDailyActivityPicker.vue';
import User from '@/model/User';
import AutomatedGoal from '@/model/AutomatedGoal';
import EventBus from '@/util/eventBus';

export default {
  name: 'Goals',
  components: {
    MyDailyActivityPicker,
    MyIconPicker,
    GoalEntry,
    PageTitle,
  },

  data: () => ({
    user: null,
    selectedIcon: null,
    selectedDailyActivity: null,
    selectedDate: null,
    menu: false,
    date: new Date().toISOString().substr(0, 10),
    addItemDialog: false,
    itemForm: {
      automatedgoal: true,
      creating: true,
      valid: false,
      instance: null,
    },
  }),

  mounted() {
    User.GetInstance()
      .then((instance) => {
        this.user = instance;
      })
      .catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });
  },

  watch: {
    /**
     * Revalidate form on change
     */
    itemForm: {
      handler() {
        this.$nextTick(() => this.$refs.form.validate());
      },
      deep: true,
    },
  },

  // if user not loaded return empty lists
  computed: {
    automatedgoals() {
      if (!this.user) {
        return [];
      }
      return [...this.user.automatedgoals];
    },
    manualgoals() {
      if (!this.user) {
        return [];
      }
      return [...this.user.manualgoals];
    },
  },

  methods: {
    /**
     * Update old goal
     * can be either a manual- or an automated goal
     */
    saveOldItem() {
      this.addItemDialog = false;
      if (this.itemForm.instance.quantity === undefined || this.itemForm.instance.quantity === null) {
        this.user.PatchManualGoal(this.itemForm.instance)
          .catch((e) => {
            EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
          });
      } else {
        this.user.PatchAutomatedGoal(this.itemForm.instance)
          .catch((e) => {
            EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
          });
      }
    },
    /**
     * Save a new item
     * can be either a manual- or an automated goal
     */
    saveNewItem() {
      this.addItemDialog = false;
      if (this.itemForm.instance.quantity === undefined || this.itemForm.instance.quantity === null) {
        this.user.AddManualGoal(this.itemForm.instance)
          .catch((e) => {
            EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
          });
      } else {
        this.user.AddAutomatedGoal(this.itemForm.instance)
          .catch((e) => {
            EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
          });
      }
    },
    /**
     * Patch goal locally
     * @param goal goal object
     */
    patch(goal) {
      this.itemForm.instance = goal;
      this.itemForm.automatedgoal = goal.quantity !== undefined;
      this.itemForm.creating = false;
      this.addItemDialog = true;
    },
    /**
     * Post goal locally
     */
    post() {
      const tomorrow = new Date(this.$moment().add(1, 'days').format()).getTime() / 1000;
      this.itemForm.instance = new AutomatedGoal(null, null, null, tomorrow, null, null, null, null);
      this.itemForm.creating = true;
      this.addItemDialog = true;
    },
    /**
     * Delete goal
     * @param goal goal object
     */
    del(goal) {
      if (goal.quantity === undefined) {
        // manualgoal
        this.user.DeleteManualgoal(goal)
          .catch((e) => {
            EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
          });
      } else {
        // automatedgoal
        this.user.DeleteAutomatedGoal(goal)
          .catch((e) => {
            EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
          });
      }
    },
    /**
     * Mark manual goal as done/to-do
     * @param manualgoal goaö object (manuel)
     * @param value boolean
     */
    doneManualGoal(manualgoal, value) {
      // eslint-disable-next-line no-param-reassign
      manualgoal.done = value;
      this.user.PatchManualGoal(manualgoal)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
    },
  },
};
</script>
