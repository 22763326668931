import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Dashboard from '../views/Dashboard.vue';
import Feedback from '../views/Feedback.vue';
import ActivityHistory from '../views/ActivityHistory.vue';
import Calendar from '../views/Calendar.vue';
import ActivityView from '../views/ActivityView.vue';
import FoodSuggestion from '../views/FoodSuggestion.vue';
import Goals from '../views/Goals.vue';
import NewActivity from '../views/NewActivity.vue';
import Profile from '../views/Profile.vue';
import Settings from '../views/Settings.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Registrieren',
    component: Register,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/history',
    name: 'Aktivitätsverlauf',
    component: ActivityHistory,
  },
  {
    path: '/calendar',
    name: 'Kalender',
    component: Calendar,
  },
  {
    path: '/activity',
    name: 'Activitätsanzeige',
    component: ActivityView,
  },
  {
    path: '/foodsuggestion',
    name: 'Ernährungsvorschlag',
    component: FoodSuggestion,
  },
  {
    path: '/goals',
    name: 'Fitnessziele',
    component: Goals,
  },
  {
    path: '/activity/new',
    name: 'Neue Aktivität',
    component: NewActivity,
  },
  {
    path: '/profile',
    name: 'Profil',
    component: Profile,
  },
  {
    path: '/settings',
    name: 'Einstellungen',
    component: Settings,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
