class Appointment {
  /**
   * Represents an appointment
   * @param {number} id
   * @param {string} name - Name of the appointment
   * @param {Date} start - Timestamp when the appointment begins
   * @param {Date} end - timestamp when the appointment ends
   * @param {string} color - Background color of the appointment
   */
  constructor(id, name, start, end, color) {
    this.id = id;
    this.start = start;
    this.end = end;
    this.name = name;
    this.color = color || null;
  }
}

export default Appointment;
