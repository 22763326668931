<template>
  <v-app>
    <HeaderMenu />
    <MyAlertSystem
      ref="alertSystem"
    />

    <v-main v-if="!loading">
      <router-view />
    </v-main>

    <v-sheet v-else
             :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
             class="pa-3 ma-3"
    >
      <v-skeleton-loader
        v-bind="skeleton_attrs"
        class="mx-auto"
        max-width="600"
        type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
      >
      </v-skeleton-loader>
    </v-sheet>

    <!-- Do not show navigation on Login/Registrieren page-->
    <bottom-navigation v-if="['Login', 'Registrieren'].indexOf($route.name) === -1"/>
  </v-app>
</template>

<script>
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import HeaderMenu from '@/components/HeaderMenu.vue';
import BottomNavigation from '@/components/BottomNavigation.vue';
import MyAlertSystem from '@/components/MyAlertSystem.vue';
import User from '@/model/User';
import EventBus from '@/util/eventBus';

export default {
  name: 'App',

  components: {
    MyAlertSystem,
    BottomNavigation,
    HeaderMenu,
  },

  inject: {
    theme: {
      default: { isDark: false },
    },
  },

  created() {
    // Handle 'user-login' Event
    EventBus.$on('user-login', () => {
      this.load();
      this.$router.push('/dashboard');
    });

    if (!User.GetToken()) {
      // no token, go to login
      this.routeLogin();
    } else {
      this.load();
    }
  },

  methods: {
    /**
     * Load user instance
     */
    load() {
      const jwt = jwt_decode(User.GetToken());

      if (Date.now() > jwt.exp * 1000) { // token expired, go to login
        this.routeLogin();
      } else {
        // token is ok, loading user instance
        this.loading = true;
        User.GetInstance()
          .then((res) => {
            this.user = res;
            this.loading = false;
          })
          .catch((e) => {
            console.log('Error hier', e);
            User.Destroy();
            User.DelToken();

            this.loading = false;
            const text = 'Fehler beim laden der Nutzerdaten';
            this.routeLogin();
            EventBus.$emit('new-snackbar', text, 'error', 5000, true);
          });
      }
    },
    /**
     * Direct to Login page
     */
    routeLogin() {
      if (this.$route.name !== 'Login') {
        this.$router.push('/');
      }
    },
  },

  data: () => ({
    user: null,
    loading: false,
    skeleton_attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 2,
    },
  }),
};
</script>
