<template>
  <div
    class="mt-16"
  >
    <v-alert
      v-for="item in alerts"
      :key="item.key"
      :color="item.color"
      :dismissible="item.dismissible"
      app
    >
      {{ item.text }}
    </v-alert>

    <v-snackbar
      v-for="item in snackbars"
      :key="item.key"
      v-model="item.show"
      :color="item.color"
      :timeout="item.timeout"
      top
      class="mt-12"
      app
    >
      {{ item.text }}

      <v-btn
        text
        @click="item.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import EventBus from '@/util/eventBus';

export default {
  name: 'MyAlertSystem',
  data() {
    return {
      alerts: [],
      snackbars: [],
    };
  },
  mounted() {
    EventBus.$on('new-alert', (text, theme, duration, dismissible) => {
      this.newAlert(text, theme, duration, dismissible);
    });
    EventBus.$on('new-snackbar', (text, theme, duration, dismissible) => {
      this.newSnackbar(text, theme, duration, dismissible);
    });
  },
  methods: {
    /**
     * Will create and display an alert on the page
     * @param text Text of the alert
     * @param color Background color of the alert
     * @param duration Duration how long the alert will be shown
     * @param dismissible Controls if the alert can be closed
     */
    newAlert(text, color, duration, dismissible) {
      const tmp = {
        key: this.uuid4(),
        text,
        color,
        dismissible,
      };
      this.alerts.push(tmp);
      if (duration) {
        setTimeout((key) => {
          this.alerts = this.alerts.filter((alert) => alert.key !== key);
        }, duration, tmp.key);
      }
    },
    /**
     * Will create and display a snackbar on the page
     * @param text Text of the snackbar
     * @param Background color of the alert
     * @param duration Duration how long the snackbar will be shown
     * @param dismissible Controls if the snackbar can be closed
     */
    newSnackbar(text, color, duration, dismissible) {
      const tmp = {
        key: this.uuid4(),
        text,
        color,
        dismissible,
        timeout: duration,
        show: true,
      };
      this.snackbars.push(tmp);
      if (duration) {
        setTimeout((key) => {
          this.snackbars = this.snackbars.filter((snackbar) => snackbar.key !== key);
        }, duration + 2000, tmp.key);
      }
    },
    /**
     * Generates a uuid4 string
     * @returns {string} uuid4 string
     */
    uuid4() {
      // eslint-disable-next-line no-bitwise, no-mixed-operators, max-len
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    },
  },
};
</script>

<style scoped>
</style>
