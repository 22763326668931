class DailyActivity {
  /**
   * DailyActivity
   * @param {number} id
   * @param {string} name Title of the activity
   * @param {string} icon Shown icon of the activity
   * @param {boolean} weekend If the activity should be done at the weekend
   * @param {Array.<number>} done Array of timestamps
   */
  constructor(id, name, weekend, icon, done) {
    this.id = id;
    this.name = name;
    this.weekend = weekend || false;
    this.icon = icon || null;
    this.done = done || [];
  }

  DoneToday() {
    const now = new Date();
    const todayStart = (new Date(now.getFullYear(), now.getMonth(), now.getDate()) / 1000);
    // eslint-disable-next-line no-unused-vars
    const todayEnd = todayStart + 86400;
    const newestDone = Math.max(...this.done);
    return newestDone > todayStart;
  }

  GenerateBoolLastWeek() {
    const ret = [false, false, false, false, false, false];
    // ToDo:
    this.done.forEach((timestamp) => {
      console.log(timestamp);
    });
    return ret;
  }
}

export default DailyActivity;
