var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('v-container',[_c('PageTitle',{attrs:{"title":"Profil"}}),_c('v-card',{staticClass:"pa-5",attrs:{"flat":"","elevation":"5"}},[_c('v-card-title',{staticClass:"justify-center display-2"},[_vm._v("Gewicht")]),_c('LineChart',{ref:"weightChart",staticStyle:{"height":"400px","width":"100%"},attrs:{"chartdata":_vm.weightGraphData,"options":_vm.weightGraphOptions}}),_c('v-row',{staticClass:"justify-center mt-5"},[_c('div',{staticClass:"text-h5"},[_vm._v("Neues Gewicht (kg) eintragen:")])]),_c('v-row',{staticClass:"mt-4 justify-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"filled":"","type":"number","value":_vm.weightInstance.value},on:{"input":function (val) { return _vm.weightInstance.value = val ? parseFloat(val) : null; }},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.weightInstance.value},on:{"click":_vm.addWeight}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-plus ")])],1)]},proxy:true}],null,false,3583617383)})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.weightHeaders,"items":_vm.weights_sorted},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.timestamp * 1000).format('DD.MM.YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteWeight(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,3023851552)})],1),_c('v-card',{staticClass:"mt-12 pa-5",attrs:{"flat":"","elevation":"5"}},[_c('v-card-title',{staticClass:"justify-center display-2"},[_vm._v("Schlaf")]),_c('LineChart',{ref:"sleepChart",staticStyle:{"height":"400px","width":"100%"},attrs:{"chartdata":_vm.sleepGraphData,"options":_vm.sleepGraphOptions}}),_c('MyFormRow',{attrs:{"title":"Schlafrythmus","col":false}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly? 12 : null}},[_c('v-menu',{ref:"menuStart",attrs:{"close-on-content-click":false,"return-value":_vm.sleepInstance.start,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.sleepInstance, "start", $event)},"update:return-value":function($event){return _vm.$set(_vm.sleepInstance, "start", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Startzeit","full-width":"","prepend-inner-icon":"mdi-clock-time-two-outline","readonly":"","clearable":"","filled":"","m":""},model:{value:(_vm.sleepInstance.start),callback:function ($$v) {_vm.$set(_vm.sleepInstance, "start", $$v)},expression:"sleepInstance.start"}},'v-text-field',attrs,false),on))]}}],null,false,872116573),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[(_vm.startMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuStart.save(_vm.sleepInstance.start)}},model:{value:(_vm.sleepInstance.start),callback:function ($$v) {_vm.$set(_vm.sleepInstance, "start", $$v)},expression:"sleepInstance.start"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly? 12 : null}},[_c('v-menu',{ref:"menuEnd",attrs:{"close-on-content-click":false,"return-value":_vm.sleepInstance.end,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.sleepInstance, "end", $event)},"update:return-value":function($event){return _vm.$set(_vm.sleepInstance, "end", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Endzeit","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","clearable":"","filled":""},model:{value:(_vm.sleepInstance.end),callback:function ($$v) {_vm.$set(_vm.sleepInstance, "end", $$v)},expression:"sleepInstance.end"}},'v-text-field',attrs,false),on))]}}],null,false,3471167050),model:{value:(_vm.endMenu),callback:function ($$v) {_vm.endMenu=$$v},expression:"endMenu"}},[(_vm.endMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuEnd.save(_vm.sleepInstance.end)}},model:{value:(_vm.sleepInstance.end),callback:function ($$v) {_vm.$set(_vm.sleepInstance, "end", $$v)},expression:"sleepInstance.end"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly? 12 : null}},[_c('v-btn',{staticClass:"mt-3",attrs:{"icon":"","disabled":!_vm.sleepInstance.end || !_vm.sleepInstance.start},on:{"click":_vm.addSleep}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.sleepHeaders,"items":_vm.sleeps_sorted,"locale":"de-DE"},scopedSlots:_vm._u([{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.start * 1000).format('HH:mm'))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.end * 1000).format('HH:mm'))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment.duration(_vm.$moment(item.end * 1000).diff(_vm.$moment(item.start * 1000))).format('HH:mm'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteSleep(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,3731397884)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }