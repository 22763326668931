class Weight {
  /**
   * Represents a Weight
   * @param {Date} timestamp
   * @param {number} value
   */
  constructor(timestamp, value) {
    this.timestamp = timestamp;
    this.value = value;
  }
}

export default Weight;
