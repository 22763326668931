const Nutrition = {
  NO: 'NO',
  DIET: 'DIET',
  MUSCLE: 'MUSCLE',
  STAMINA: 'STAMINA',
};

export const NutritionTranslate = [
  {
    text: '-',
    value: Nutrition.NO,
  },
  {
    text: 'Abnehmen',
    value: Nutrition.DIET,
  },
  {
    text: 'Muskelaufbau',
    value: Nutrition.MUSCLE,
  },
  {
    text: 'Ausdauer',
    value: Nutrition.STAMINA,
  },
];

export default Nutrition;
