<template>
  <v-select
    :items="icons_available"
    :value="value"
    @input="(v) => $emit('input', v)"
    label="Icon"
    item-text="name"
    item-value="icon"
    clearable
  >
    <template v-slot:prepend>
      <img
        :src="require('../assets/icons/'+ (value ? value : 'no.png'))"
        class="selectIcon"
      >
    </template>
    <template v-slot:item="{ item }">
      <img
        :src="require('../assets/icons/'+item.icon)"
        class="selectIcon mr-3"
      >
      <span>{{ item.name }}</span>
    </template>
  </v-select>
</template>

<script>
import icons from '@/assets/icons/icons';

export default {
  name: 'MyIconPicker',
  props: {
    value: {
      type: String,
      default: 'no.png',
    },
  },

  data: () => ({
    icons_available: icons,
  }),
};
</script>

<style scoped>
  .selectIcon {
    height: 15px;
  }

</style>
