<template>
    <v-app v-if="activity">
        <ActivityMap v-if="activity.tracker === 'map'" :activity="activity"></ActivityMap>
        <ActivityClicker v-if="activity.tracker === 'clicker'"  :activity="activity"></ActivityClicker>
        <ActivityTimer v-if="activity.tracker === 'timer'"  :activity="activity"></ActivityTimer>
    </v-app>
</template>

<script>
import ActivityMap from '../components/ActivityMap.vue';
import ActivityClicker from '../components/ActivityClicker.vue';
import ActivityTimer from '../components/ActivityTimer.vue';

export default {
  name: 'ActivityView',
  components: {
    ActivityTimer,
    ActivityClicker,
    ActivityMap,
  },
  mounted() {
    this.activity = JSON.parse(localStorage.getItem('currentActivity'));
    if (this.activity === null) {
      this.$router.push('/activity/new');
    }
  },
  data: () => ({
    activity: null,
  }),
};
</script>
