import Nutrition from '@/model/Nutrition';

/* eslint-disable max-len */
const FoodItems = [
  {
    title: 'Sautéed Broccoli',
    image: 'https://images.unsplash.com/photo-1584270354949-c26b0d5b4a0c?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    caption: 'Broccoli rockt!',
    text: 'If you\'re in search of a green vegetable side dish, then look no further. This simple but delicious preparation produces perfectly cooked broccoli florets every time. A little olive oil, garlic, and lemon juice are all of the seasoning you need, but feel free to jazz it up with other ingredients if you like.\nThis two-step sautéed broccoli recipe produces a wonderful bright green color and crisp-tender texture. It\'s the perfect side dish for many main dishes, including grilled steak, roasted chicken, meatloaf, or a pork roast. Once you know how to make broccoli using this method....',
    tags: [Nutrition.NO, Nutrition.STAMINA, Nutrition.MUSCLE, Nutrition.DIET],
  },
  {
    title: 'Ausdauer Essen 1',
    image: '',
    caption: '',
    text: '',
    tags: [Nutrition.STAMINA],
  },
  {
    title: 'Muskelaufbau Essen 1',
    image: 'https://img.chefkoch-cdn.de/rezepte/1357451240492974/bilder/1203342/crop-960x720/ruehrei-mit-raeucherlachs-und-schnittlauch.jpg',
    caption: 'Rührei',
    text: 'Das Rührei beinhaltet viele wertvolle Proteine für deinen Muskelaufbau',
    tags: [Nutrition.MUSCLE],
  },
  {
    title: 'Diät Essen 1',
    image: 'https://spinnrad.s3-de-central.profitbricks.com/media/image/00/e1/b2/0022110580_0.jpg',
    caption: 'Skyr mit Erdbeeren',
    text: 'Skyr ist ein traditionelles isländisches Milchprodukt aus Kuh- oder Schafsmilch.',
    tags: [Nutrition.DIET],
  },
  {
    title: 'Alle außer Diät Essen',
    image: 'https://i.ytimg.com/vi/bxlOHlVf2Tc/maxresdefault.jpg',
    caption: 'Dein Cheatmeal um zuzunehmen',
    text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut '
        + 'labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et '
        + 'ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    tags: [Nutrition.NO, Nutrition.STAMINA, Nutrition.MUSCLE],
  },
  {
    title: '2 x Big King XXL Coupon + mittlere Pommes & 0.4 Cola',
    image: 'https://www.burgerking.de/tmp/image-thumb__15394__product_detail/tmp-1097-de.png',
    caption: 'Gutscheincode: B42',
    text: 'Gönn dir!',
    tags: [Nutrition.NO],
  },
];

export default FoodItems;
