<template>
  <v-container style="width: 80%">
    <PageTitle :title="currentFood.title"></PageTitle>
    <v-img
      v-if="currentFood.image"
      contain
      :src="currentFood.image"
      max-height="45vh"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey darken-5"
          />
        </v-row>
      </template>
    </v-img>
    <v-card class="mt-5">
      <v-card-title>
        {{ currentFood.caption }}
      </v-card-title>
      <v-card-text>
        {{ currentFood.text }}
      </v-card-text>
    </v-card>
    <v-btn
      color="primary"
      style="top: 70%; right: 5px; position: fixed"
      absolute
      @click="changeFood"
    >
      <v-icon>
        mdi-arrow-right
      </v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import User from '@/model/User';
import { NutritionTranslate } from '@/model/Nutrition';
import FoodItems from '@/model/FoodItems';
import EventBus from '@/util/eventBus';

export default {
  name: 'FoodSuggestion',

  components: {
    PageTitle,
  },

  mounted() {
    User.GetInstance()
      .then((instance) => {
        this.user = instance;
        this.nutrition = NutritionTranslate.filter((item) => item.value === this.user.nutrition)[0].text;
        this.changeFood();
      })
      .catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });
  },

  data: () => ({
    user: null,
    nutrition: null,
    currentFood: {
      title: '', image: '', caption: '', text: '',
    },
    foodItems: FoodItems,
  }),

  methods: {
    /**
     * Get random food with according tag(Fitness-mode)
     * @return {*} food suggestion object
     */
    rollFood() {
      // eslint-disable-next-line no-unused-vars
      const filteredFoodItems = this.foodItems.filter((item) => item.tags.includes(this.user.nutrition));

      return filteredFoodItems[
        Math.floor(Math.random() * filteredFoodItems.length)
      ];
    },
    /**
     * Change content to a new food suggestion
     */
    changeFood() {
      let newFood = this.currentFood;
      while (JSON.stringify(newFood) === JSON.stringify(this.currentFood)) {
        newFood = this.rollFood();
      }

      this.currentFood = newFood;
    },
  },
};
</script>
