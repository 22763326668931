<template>
    <v-col cols="6" md="3" style="height: 220px">
        <v-card color="primary pa-5 text-center" style="color: white; border-radius: 50px; height: 100%" @click="(e) => $emit('click', e)">
            <v-row align="center" justify="center">
                <div v-if="count !== undefined">
                    <v-col cols="12" class="pt-6">
                        <span class="display-3 font-weight-medium">{{count}}</span>
                        <span>{{unit}}</span>
                    </v-col>
                    <v-col cols="12">
                        <v-icon v-if="icon">{{icon}}</v-icon>
                      <span class="headline">{{title}}</span>
                    </v-col>
                </div>
                <div v-else>
                  <v-col cols="12" class="pt-12">
                      <v-icon v-if="icon" color="white" class="display-2 mb-2">{{icon}}</v-icon><br>
                    <span class="headline">{{ title }}</span>
                  </v-col>
                </div>
            </v-row>
        </v-card>
    </v-col>
</template>

<script>
export default {
  name: 'DashboardCube',
  props: {
    title: {
      type: String,
      default: 'Gewicht',
    },
    count: {
      type: Number,
      default: undefined,
    },
    unit: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
};
</script>

<style scoped>

</style>
