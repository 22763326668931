const ActivityType = {
  RUN: 'RUN',
  BIKE: 'BIKE',
  SIT_UPS: 'SIT_UPS',
  PUSH_UPS: 'PUSH_UPS',
  SWIM: 'SWIM',
  SQUATS: 'SQUATS',
};

export const ActivityTypeTranslate = [
  {
    type: ActivityType.RUN,
    text: 'Laufen',
    icon: 'running.png',
    tracker: 'map',
  },
  {
    type: ActivityType.BIKE,
    text: 'Fahrrad fahren',
    icon: 'bicycle.png',
    tracker: 'map',
  },
  {
    type: ActivityType.SIT_UPS,
    text: 'Rumpfbeugen',
    icon: 'situps.png',
    tracker: 'clicker',
  },
  {
    type: ActivityType.PUSH_UPS,
    text: 'Liegestütze',
    icon: 'pushups.png',
    tracker: 'clicker',
  },
  {
    type: ActivityType.SQUATS,
    text: 'Kniebeugen',
    icon: 'squat.png',
    tracker: 'clicker',
  },
  {
    type: ActivityType.SWIM,
    text: 'Schwimmen',
    icon: 'swim.png',
    tracker: 'timer',
  },
];

export default ActivityType;
