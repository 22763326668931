var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"rounded":"","tile":_vm.tile,"color":_vm.colored ? _vm.appointment.color : 'primary'}},[(!_vm.colored)?_c('v-card-title',{staticStyle:{"color":"whitesmoke"}},[_vm._v(" Termin hinzufügen ")]):_vm._e(),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('MyFormRow',{attrs:{"title":"Name"}},[_c('v-text-field',{attrs:{"filled":"","clearable":"","background-color":"white","rules":[_vm.rules.required, _vm.rules.min, _vm.rules.max]},model:{value:(_vm.appointment.name),callback:function ($$v) {_vm.$set(_vm.appointment, "name", $$v)},expression:"appointment.name"}})],1),_c('MyFormRow',{attrs:{"title":"Tag"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedDateFormattedMomentjs,"readonly":"","filled":"","prepend-inner-icon":"mdi-calendar","background-color":"white"},on:{"click:clear":function($event){_vm.dayField = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dayMenu),callback:function ($$v) {_vm.dayMenu=$$v},expression:"dayMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.isodate,"locale":"de-DE","full-width":""},on:{"input":_vm.setAppointmentDate,"change":function($event){_vm.dayMenu = false}}})],1)],1),_c('MyFormRow',{attrs:{"title":"Zeitraum","col":false}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly? 12 : null}},[_c('v-menu',{ref:"menuStart",attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.getAppointmentStartHour,"disabled":!_vm.appointment.start,"label":"Startzeit","prepend-inner-icon":"mdi-clock-time-two-outline","readonly":"","filled":"","background-color":"white"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[_c('v-time-picker',{attrs:{"value":_vm.getAppointmentStartHour,"format":"24hr","full-width":""},on:{"input":_vm.setAppointmentStart,"click:minute":function($event){_vm.startMenu = false}}})],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly? 12 : null}},[_c('v-menu',{ref:"menuEnd",attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.getAppointmentEndHour,"disabled":!_vm.appointment.start,"label":"Endzeit","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","filled":"","background-color":"white"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endMenu),callback:function ($$v) {_vm.endMenu=$$v},expression:"endMenu"}},[_c('v-time-picker',{attrs:{"value":_vm.getAppointmentEndHour,"min":_vm.getAppointmentStartHour,"format":"24hr","full-width":""},on:{"input":_vm.setAppointmentEnd,"click:minute":function($event){_vm.endMenu = false}}})],1)],1)],1),_c('v-menu',{ref:"colorMenu",attrs:{"close-on-content-click":false,"offset-overflow":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"2px solid black !important"},attrs:{"fab":"","large":"","color":_vm.appointment.color},on:{"click":function($event){_vm.colorMenu = true}}},'v-btn',attrs,false),on))]}}]),model:{value:(_vm.colorMenu),callback:function ($$v) {_vm.colorMenu=$$v},expression:"colorMenu"}},[_c('v-color-picker',{staticClass:"no-alpha",attrs:{"hide-inputs":""},model:{value:(_vm.appointment.color),callback:function ($$v) {_vm.$set(_vm.appointment, "color", $$v)},expression:"appointment.color"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{style:(_vm.valid ? '' : 'background-color: #BC8989!important;'),attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":function($event){return _vm.$emit('save')}}},[_vm._v(" Speichern ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }