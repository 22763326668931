<template>
  <v-container>
    <PageTitle title="Terminplaner"></PageTitle>
    <v-container v-if="user">
      <v-calendar
        ref="calendar"
        locale="de-DE"
        :now="today"
        :value="today"
        :events="appointments"
        color="primary"
        type="week"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        style="height: 600px"
        @click:event="showEvent"
      />

      <v-menu
        v-model="selectedOpen"
        :close-on-click="false"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card
          v-if="selectedEvent"
          color="grey lighten-4"
          min-width="350px"
          flat
        >
          <v-toolbar
            :color="selectedEvent.color"
            dark
          >
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="deleteEvent"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-0 ma-0">
            <AppointmentForm
              :appointment="selectedEvent"
              class="pa-0 ma-0"
              colored
              tile
              @save="patchEvent"
            />
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              text
              color="secondary"
              @click="closeEditMenu"
            >
              Abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <AppointmentForm
        :appointment="newEvent"
        class="mt-12"
        @save="addEvent"
      />

    </v-container>
  </v-container>
</template>

<script>
import 'moment';
import AppointmentForm from '@/components/AppointmentForm.vue';
import PageTitle from '@/components/PageTitle.vue';
import User from '@/model/User';
import Appointment from '@/model/Appointment';
import EventBus from '@/util/eventBus';

export default {
  name: 'Calendar',

  components: {
    AppointmentForm,
    PageTitle,
  },

  data: () => ({
    user: null,
    valid: false,
    today: '',
    selectedEvent: null,
    selectedElement: null,
    selectedOpen: false,
    newEvent: new Appointment(0, '', new Date().getTime() / 1000, new Date().getTime() / 1000 + 60 * 60, 'red'),
  }),

  created() {
    this.today = this.$moment(new Date()).format('YYYY-MM-DD');
  },

  computed: {
    appointments() {
      return this.user ? this.user.appointments.map((item) => ({
        id: item.id,
        name: item.name,
        start: this.$moment(item.start * 1000).format('YYYY-MM-DD HH:mm'),
        end: this.$moment(item.end * 1000).format('YYYY-MM-DD HH:mm'),
        color: item.color,
      })) : [];
    },
  },

  mounted() {
    User.GetInstance()
      .then((instance) => {
        this.user = instance;
        this.$nextTick(() => this.$refs.calendar.scrollToTime(new Date().getHours()));
      })
      .catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });
  },

  methods: {
    /**
     * Closes the edit menu
     */
    closeEditMenu() {
      this.selectedOpen = false;
      User.GetAppointments().then((response) => { this.user.appointments = response; });
    },
    /**
     * Will show the appointment editor for the clicked event
     * @param nativeEvent Click event
     * @param event Appointment object
     */
    showEvent({ nativeEvent, event }) {
      const open = () => {
        // eslint-disable-next-line prefer-destructuring
        this.selectedEvent = this.user.appointments.filter((itm) => itm.id === event.id)[0];
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    /**
     * Will create a new appointment
     */
    addEvent() {
      this.user.AddAppointment(this.newEvent)
        .then(() => {
        // eslint-disable-next-line max-len
          this.newEvent = new Appointment(0, '', new Date().getTime() / 1000, new Date().getTime() / 1000 + 60 * 60, 'red');
        })
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
    },
    /**
     * Will update the appointment data of the selected appointment
     */
    patchEvent() {
      this.user.PatchAppointment(this.selectedEvent)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
      this.selectedOpen = false;
      this.selectedEvent = null;
      this.selectedElement = null;
    },
    /**
     * Will delete the selected appointment
     */
    deleteEvent() {
      this.user.DeleteAppointment(this.selectedEvent)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
      this.selectedOpen = false;
      this.selectedEvent = null;
      this.selectedElement = null;
    },
  },
};
</script>
