<template>
  <v-row>
    <v-col
      cols="12"
      sm="4"
      lg="2"
      xl="2"
    >
      <h4 class="text-h5">
        {{ title + (title ? ':' : '') }}
      </h4>
    </v-col>

    <v-col v-if="col">
      <slot />
    </v-col>
    <slot v-else/>
  </v-row>
</template>

<script>
export default {
  name: 'MyFormRow',
  props: {
    title: {
      type: String,
      required: true,
    },
    col: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
