<template>
    <v-card
      color="white"
      :class="disable ? 'myDisabled' : ''"
    >
      <v-card-text style="color: black" class="ma-2">
        <v-row align="center" justify="center">
          <v-col cols="2" sm="1">
            <v-checkbox
              :readonly="isAutomatedGoal"
              :input-value="checked"
              v-on:change="done"
            />
          </v-col>
          <v-col cols="1" v-if="$vuetify.breakpoint.smAndUp">
            <img :src="require('../assets/icons/' + (goal.icon || 'no.png'))" class="sportIcon">
          </v-col>

          <v-col cols="6">
            <span v-if="isAutomatedGoal" class="mr-2 ">
              <span class="headline font-weight-light">{{ goal.quantity }}</span>
              x
            </span>
            <span class="headline">{{ goal.name }}</span>
          </v-col>

          <v-col cols="2" v-if="$vuetify.breakpoint.smAndUp">
            {{ $moment(goal.end * 1000).format('DD.MM.YYYY') }}
          </v-col>

          <v-col cols="2" sm="1">
            <v-btn
              icon
              color="warning"
              @click="$emit('patch-goal', goal)"
            >
              <v-icon>mdi-pen</v-icon>
            </v-btn>
              <v-btn
                icon
                color="error"
                @click="$emit('delete-goal', goal)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-progress-linear
        color="blue"
        height="5"
        :value="progress"
      />
    </v-card>
</template>

<script>
import 'moment';

export default {
  name: 'GoalEntry',
  props: {
    goal: {
      type: Object,
      required: true,
    },
  },

  computed: {
    /**
     * Checks if this goal is automated
     * @returns {boolean} is this goal an automated goal
     */
    isAutomatedGoal() {
      return !!this.goal.quantity;
    },
    /**
     * Returns the progress of this goal in per cent if this isn't an automated goal
     * @returns {number} Progress in per cent
     */
    progress() {
      if (!this.isAutomatedGoal) {
        const now = new Date().getTime() / 1000;
        const diff = this.goal.end - this.goal.created;
        return ((now - this.goal.created) / diff) * 100;
      }
      return this.goal.progress ? this.goal.progress * 100 : 0.0;
    },
    /**
     * Checks if this goal is completed and should be checked in the UI
     * @returns {boolean|*} if goal should be checked in the UI
     */
    checked() {
      if (!this.isAutomatedGoal) {
        // manual goal
        return this.goal.done;
      }
      // automated goal
      return this.progress >= 100.0;
    },
    /**
     * Checks if goal is expired
     * @returns {boolean|*} goal is expired
     */
    disable() {
      return this.checked || this.goal.end < new Date().getTime() / 1000;
    },
  },

  methods: {
    done() {
      this.$emit('change', this.goal, !this.goal.done);
    },
  },
};
</script>

<style scoped>
    .sportIcon{
      height: 35px;
    }
    .myDisabled{
      opacity: 0.6;
    }
</style>
