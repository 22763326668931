<template>
    <v-app>
        <v-dialog
                v-model="errorDialog"
                persistent
                width="500"
        >
            <v-card>
                <v-card-title class="headline error white--text">
                    Fehler bei Standortermittlung
                </v-card-title>

                <v-card-text class="mt-5">
                    Die Aktivität kann leider nicht getrackt werden, da der Standort des Geräts nicht ermittelt werden
                    kann.<br>
                    Stelle sicher, dass die Webseite die Berechtigung hat auf deinen Standort zuzugreifen
                    und du über ein GPS Modul im Gerät verfügst.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            to="/dashboard"
                            text
                            @click="errorDialog = false"
                    >
                        Zum Dashboard
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ActivitySummaryDialog
                :show-dialog="summaryDialog"
                :stopwatch-string="stopwatchString"
                :distance="locationWatcher.distance"
                :pace="averagePace"/>
        <v-layout column fill-height style="flex-basis: 0">
            <v-flex shrink>
                <PageTitle :title="activity.text" class="pt-3"></PageTitle>
                <v-row class="text-center">
                    <v-col>
                        <h1 class="display-1">{{stopwatchString}}</h1>
                        <h6>Dauer</h6>
                    </v-col>
                    <v-col>
                        <h1 class="display-1">{{locationWatcher.speed}}<span
                                class="title font-weight-medium">km/h</span></h1>
                        <h6>Geschwindigkeit</h6>
                    </v-col>
                </v-row>
                <v-row class="text-center">
                    <v-col>
                        <h1 class="display-1">{{(locationWatcher.distance/1000).toFixed(2)}}
                            <span class="title font-weight-medium">km</span></h1>
                        <h6>Distanz</h6>
                    </v-col>
                    <v-col class="grey--text">
                        <h1 class="display-1">-- <span
                                class="title font-weight-medium">kcal</span></h1>
                        <h6>Kalorien</h6>
                    </v-col>
                    <v-col>
                        <h1 class="display-1">{{averagePace === Infinity ? '-- ' : mmss((averagePace*60).toFixed(0))}}
                            <span class="title font-weight-medium">min/km</span></h1>
                        <h6>Tempo (Durchschnitt)</h6>
                    </v-col>
                </v-row>
                <div class="text-center ma-5" @click="endActivity">
                    <v-btn color="primary">
                        Aktivität beenden
                    </v-btn>
                </div>
            </v-flex>

            <v-flex>
                <l-map
                        ref="activityMap"
                        :zoom="zoom"
                        :center="center"
                        :min-zoom="10"
                        style="height: calc(100% - 75px); z-index: 0"
                >
                    <l-tile-layer
                            :url="url"
                            :attribution="attribution"
                    />
                    <l-polyline :lat-lngs="polyline.latlngs" :color="polyline.color"></l-polyline>
                    <v-locatecontrol ref="locationController" :options="locateOptions" visible/>
                </l-map>
            </v-flex>
        </v-layout>
    </v-app>
</template>

<script>
import { latLng } from 'leaflet';
import { getDistance } from 'geolib';
import Stopwatch from 'statman-stopwatch';
import User from '@/model/User';
import Activity from '@/model/Activity';
import { hhmmss, mmss } from '@/util/time';
import EventBus from '@/util/eventBus';
import PageTitle from './PageTitle.vue';
import ActivitySummaryDialog from './ActivitySummaryDialog.vue';

export default {
  name: 'ActivityMap',

  components: {
    ActivitySummaryDialog,
    PageTitle,
  },

  props: {
    activity: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    user: null,
    polyline: {
      latlngs: [],
      color: 'green',
    },
    locateOptions: {
      setView: 'untilPan',
      flyTo: true,
      locateOptions: {
        watch: true,
        setView: true,
        enableHighAccuracy: true,
      },
    },
    locationWatcher: {
      id: null, active: false, position: null, speed: 0, distance: 0,
    },
    zoom: 8,
    center: latLng(52.817765, 9.42807),
    url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                + ' contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    errorDialog: false,
    summaryDialog: false,
    measuredVelocities: [],
    stopwatch: {
      time: 0, watcher: null, start: null, end: null,
    },
  }),

  computed: {
    stopwatchString() {
      return hhmmss(this.stopwatch.time);
    },
    /**
     * Calculates the average pace of the collected velocity mesaurements
     */
    averagePace() {
      let averageVelocity = 0;
      this.measuredVelocities.forEach((velocity) => {
        averageVelocity += velocity;
      });
      averageVelocity /= this.measuredVelocities.length;
      return 60 / averageVelocity;
    },
  },

  mounted() {
    // Get user data from API
    User.GetInstance()
      .then((usr) => {
        this.user = usr;
      })
      .catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });

    // css tweak: hide scrollbar
    document.documentElement.style.overflow = 'hidden';

    this.activateLocationWatcher();
    setTimeout(() => {
      this.$refs.locationController.mapObject.start();
    }, 500);

    // Create and start stopwatch
    this.stopwatch.watcher = new Stopwatch();
    this.stopwatch.start = Math.round(Date.now() / 1000);

    // Display the stopwatch count every second
    setInterval(() => {
      this.stopwatch.time = Math.round(this.stopwatch.watcher.read(0) / 1000);
    }, 1000);
  },

  beforeDestroy() {
    // Enables the scroll bar
    document.documentElement.style.overflow = '';
    // Deletes information about the current activity because it is ended now
    localStorage.removeItem('currentActivity');
  },

  methods: {
    mmss,
    /**
     * initialize the location watcher of the browser and handles the data for it
     */
    activateLocationWatcher() {
      const success = (data) => {
        this.locationWatcher.active = true;

        // Start stopwatch if not already started
        if (this.stopwatch.watcher.state() === 'init') {
          this.stopwatch.watcher.start();
        }

        if (this.locationWatcher.position) {
          // calc distance
          const distance = getDistance({ latitude: data.coords.latitude, longitude: data.coords.longitude },
            {
              latitude: this.locationWatcher.position.latitude,
              longitude: this.locationWatcher.position.longitude,
            });
          this.locationWatcher.distance += distance;
        }
        this.locationWatcher.position = data.coords;

        const speed = Math.round(data.coords.speed * 3.6); // convert speed from m/s to km/h
        this.locationWatcher.speed = speed;
        if (this.stopwatch.end === null) {
          this.measuredVelocities.push(speed);
        }

        // Update Polyline
        this.polyline.latlngs.push([data.coords.latitude, data.coords.longitude]);
      };

      const error = (gpsErr) => {
        this.locationWatcher.active = false;
        this.errorDialog = true;
        console.error(gpsErr);
      };

      const options = {
        enableHighAccuracy: true,
      };

      this.locationWatcher.id = navigator.geolocation.watchPosition(success, error, options);
    },

    /**
     * Ends the activity. Stops the stopwatch and sends the collected data to the backend
     */
    endActivity() {
      this.stopwatch.watcher.stop();
      const activityData = {
        polyline: this.polyline,
        distance: this.locationWatcher.distance,
        pace: this.averagePace,
        calories: null,
      };
      const endedActivity = new Activity(
        -1,
        this.activity.type,
        this.stopwatch.start,
        Math.round(Date.now() / 1000),
        activityData,
      );
      this.user.AddActivity(endedActivity)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
      this.summaryDialog = true;
    },
  },

};
</script>

<style scoped>
    @import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

    html {
        overflow-y: hidden;
    }
</style>
