<template>
  <v-container v-if="user">
    <PageTitle title="Optionen"/>
    <div class="ma-0">
      <!-- Fitness-Mode row -->
      <MyFormRow
        title="Fitness-Mode"
      >
        <v-select
          v-model="user.nutrition"
          :items="selectItems"
          value="text"
          filled
        />
      </MyFormRow>

      <!-- Time row -->
      <MyFormRow
        title="Schlafrythmus"
        :col="false"
      >
        <v-col
          :cols="$vuetify.breakpoint.xsOnly? 12 : null"
        >
          <v-menu
            ref="menuStart"
            v-model="startMenu"
            :close-on-content-click="false"
            :return-value.sync="user.sleep_start"
            transition="scale-transition"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="user.sleep_start"
              label="Startzeit"
              full-width
              prepend-inner-icon="mdi-clock-time-two-outline"
              readonly
              clearable
              filled
              v-bind="attrs"
              v-on="on"
              m
            />
          </template>
          <v-time-picker
            v-if="startMenu"
            v-model="user.sleep_start"
            format="24hr"
            @click:minute="$refs.menuStart.save(user.sleep_start)"
          />
        </v-menu>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.xsOnly? 12 : null"
        >
          <v-menu
          ref="menuEnd"
          v-model="endMenu"
          :close-on-content-click="false"
          :return-value.sync="user.sleep_end"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="user.sleep_end"
              label="Endzeit"
              prepend-inner-icon="mdi-clock-time-four-outline"
              readonly
              clearable
              filled
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-if="endMenu"
            v-model="user.sleep_end"
            format="24hr"
            @click:minute="$refs.menuEnd.save(user.sleep_end)"
          />
        </v-menu>
        </v-col>
      </MyFormRow>

      <!-- Save -->
      <v-row>
        <v-col>
          <v-btn
            block
            dark
            color="green"
            @click="patch"
          >
            Speichern
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="my-8"/>

      <!-- Konto löschen row -->
      <MyFormRow
        title="Konto"
        :col="false"
      >
        <v-col>
          <v-btn
            block
            dark
            color="red"
            @click="logout"
          >
            Abmelden
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            block
            dark
            color="red"
            @click="confirmDeleteDialog = true"
          >
            Löschen
          </v-btn>
        </v-col>
      </MyFormRow>

      <!-- Tägliche Aktivitäten table -->
      <v-divider class="my-8"/>
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Tägliche Aktivitäten</v-toolbar-title>
            <v-spacer/>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="createItem()"
            >
              <v-icon x-large>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="addItemDialog"
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">
                    Tägliche Aktivität {{ itemForm.creating ? 'hinzufügen' : 'bearbeiten'}}
                  </span>
                </v-card-title>

                <v-card-text>
                  <v-container v-if="itemForm.instance">
                    <v-form v-model="itemForm.valid">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="itemForm.instance.name"
                            label="Name*"
                            clearable
                            :rules="rules"
                          />
                        </v-col>
                        <v-col cols="12">
                          <MyIconPicker
                            v-model="itemForm.instance.icon"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="itemForm.instance.weekend"
                            label="Wochenende"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="addItemDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!itemForm.valid"
                    @click="itemForm.creating ? saveNewItem() : saveOldItem()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="editTableItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteFromTable(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.weekend="{ item }">
          <v-simple-checkbox
            v-model="item.weekend"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.icon="{ item }">
          <img
            :src="require('../assets/icons/'+ (item.icon || 'no.png'))"
            class="tableIcon mr-3"
          >
        </template>
      </v-data-table>
  </div>
  <v-dialog
    v-model="confirmDeleteDialog"
    max-width="600"
  >
    <v-card>
      <v-card-title class="justify-center">
        Sind die sicher, dass Sie ihr Konto löschen wollen?
      </v-card-title>
      <v-card-actions>
        <v-btn
          block
          dark
          color="red"
          class="mb-2"
          @click="deleteAccount"
        >
          Löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import MyFormRow from '@/components/MyFormRow.vue';
import MyIconPicker from '@/components/MyIconPicker.vue';
import User from '@/model/User';
import { NutritionTranslate } from '@/model/Nutrition';
import DailyActivity from '@/model/DailyActivity';
import EventBus from '@/util/eventBus';
import { deleteSubscription } from '@/subscribe';

export default {
  name: 'Settings',
  components: {
    MyIconPicker,
    MyFormRow,
    PageTitle,
  },

  mounted() {
    User.GetInstance()
      .then((instance) => {
        this.user = instance;
      })
      .catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });
  },

  data() {
    return {
      user: null,
      rules: [(v) => (v && v.length >= 3) || 'Name zu kurz'],
      startMenu: false,
      endMenu: false,
      confirmDeleteDialog: false,
      password: null,
      addItemDialog: false,
      itemForm: {
        creating: true,
        valid: false,
        instance: null,
      },
      tableHeaders: [
        { text: 'Icon', value: 'icon' },
        { text: 'Name', value: 'name' },
        { text: 'Wochenende', value: 'weekend' },
        { text: 'Aktionen', value: 'actions' },
      ],
      editedItem: null,
      selectItems: NutritionTranslate,
    };
  },

  computed: {
    tableItems() {
      return this.user.dailyactivities || [];
    },
  },

  methods: {
    /**
     * Patches user settings
     */
    patch() {
      console.log('patch user', this.user);
      this.user.PatchUser().then((res) => {
        console.log('patchok', res);
      }).catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });
    },
    /**
     * Delete Account
     */
    deleteAccount() {
      this.user.DeleteUser()
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
      this.logout();
    },
    /**
     * Log the user out
     * Destroys Token and unsubscribes form UserAgent(Push Notification)
     */
    logout() {
      User.DelToken();
      User.Destroy();

      // Delete PushAPI subscription
      navigator.serviceWorker.ready
        .then((worker) => {
          deleteSubscription(worker);
        });

      // eslint-disable-next-line no-restricted-globals
      location.reload();
    },
    /**
     * Create daily activity locally
     */
    createItem() {
      this.itemForm.creating = true;
      this.itemForm.instance = new DailyActivity(0, null, true, null, null);
      this.addItemDialog = true;
    },
    /**
     * Save the new daily activity (backend)
     */
    saveNewItem() {
      this.addItemDialog = false;
      this.user.AddDailyactivity(this.itemForm.instance)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
    },
    /**
     * Update an old daily activity
     */
    saveOldItem() {
      this.addItemDialog = false;
      this.user.PatchDailyactivity(this.itemForm.instance)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
    },
    /**
     * Delete entry from data-table
     * @param item table item
     */
    deleteFromTable(item) {
      this.user.DeleteDailyactivity(item)
        .catch((e) => {
          EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
        });
    },
    editTableItem(item) {
      this.itemForm.creating = false;
      this.itemForm.instance = item;
      this.addItemDialog = true;
    },
  },

};
</script>

<style scoped>
  .tableIcon {
    height: 80%;
  }
</style>
