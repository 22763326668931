<template>
  <v-container>
    <PageTitle title="Aktivitätsverlauf" />
    <div>
      <MyFormRow
        title="Typ"
      >
        <v-select
          v-model="selected"
          :items="activityTypes"
          clearable
          value="text"
          filled
          return-object
          class="mt-n4"
        />
      </MyFormRow>

      <!-- Time row -->
      <MyFormRow
        title="Zeitraum"
        :col="false"
      >
        <v-col
          :cols="$vuetify.breakpoint.xsOnly? 12 : null"
          class="mt-n4"
        >
          <v-menu
            ref="menuStart"
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind:value="startTime ? $moment(startTime).format('DD.MM.YYYY') : ''"
                label="Startdatum"
                full-width
                prepend-inner-icon="mdi-calendar"
                append-outer-icon="mdi-close"
                readonly
                filled
                v-bind="attrs"
                v-on="on"
                @click:append-outer="startTime = null"
              />
            </template>
            <v-date-picker
              v-if="startMenu"
              v-model="startTime"
              format="24hr"
              locale="de-DE"
              full-width
              @click:minute="$refs.menuStart.save(startTime)"
              :max="endTime"
            />
          </v-menu>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.xsOnly? 12 : null"
          class="mt-n4"
        >
          <v-menu
            ref="menuEnd"
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind:value="endTime ? $moment(endTime).format('DD.MM.YYYY') : ''"
                label="Enddatum"
                full-width
                prepend-inner-icon="mdi-calendar"
                append-outer-icon="mdi-close"
                readonly
                filled
                v-bind="attrs"
                v-on="on"
                @click:append-outer="endTime = null"
              />
            </template>
            <v-date-picker
              v-if="endMenu"
              v-model="endTime"
              format="24hr"
              locale="de-DE"
              full-width
              @click:minute="$refs.menuEnd.save(endTime)"
            />
          </v-menu>
        </v-col>
      </MyFormRow>
    </div>
    <v-data-table
      :headers="headers"
      :items="history"
      class="mt-8"
      locale="de-DE"
    >
      <template v-slot:top>
        <span class="text-h5">Aktivitäten</span>
      </template>
      <template v-slot:item.type="{ item }">
        {{ item.type_parsed ? item.type_parsed.text : '' }}
      </template>
      <template v-slot:item.cal="{ item }">
        {{ item.data.calories ? item.data.calories : ''}}
      </template>
      <template v-slot:item.duration="{ item }">
        {{ hhmmss(item.end - item.start) }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ $moment(item.start * 1000).format('DD.MM.YYYY - HH:MM[Uhr]') }}
      </template>
      <!-- define item action -->
      <template v-slot:item.actions="{ item }">
        <v-icon color="#ccc">
          mdi-magnify
        </v-icon>
        <v-icon
          @click="deleteActivity(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import MyFormRow from '@/components/MyFormRow.vue';
import User from '@/model/User';
import { ActivityTypeTranslate } from '@/model/ActivityType';
import EventBus from '@/util/eventBus';
import { hhmmss } from '@/util/time';
import PageTitle from '../components/PageTitle.vue';

export default {
  name: 'ActivityHistory',

  components: {
    MyFormRow,
    PageTitle,
  },

  data() {
    return {
      user: null,
      selected: null,
      activityTypes: ActivityTypeTranslate,
      startMenu: false,
      endMenu: false,
      startTime: null,
      endTime: null,
      headers: [
        { text: 'Typ', value: 'type', sortable: true },
        { text: 'Kalorien (kcal)', value: 'cal', sortable: true },
        { text: 'Dauer', value: 'duration', sortable: false },
        { text: 'Datum', value: 'date', sortable: false },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ],
    };
  },

  created() {
    User.GetInstance()
      .then((instance) => {
        this.user = instance;
      })
      .catch((e) => {
        EventBus.$emit('new-snackbar', e.text(), 'error', 5000, true);
      });
  },

  computed: {
    history() {
      if (!this.user) {
        return [];
      }
      let ret = this.user.activities.map((activity) => {
        // eslint-disable-next-line no-param-reassign,prefer-destructuring
        activity.type_parsed = ActivityTypeTranslate.filter((at) => at.type === activity.type)[0];
        return activity;
      });

      if (this.selected) {
        ret = ret.filter((activity) => activity.type === this.selected.type);
      }

      if (this.startTime) {
        const dat = new Date(this.startTime).getTime() / 1000;
        ret = ret.filter((activity) => activity.start >= dat);
      }

      if (this.endTime) {
        const dat = new Date(this.endTime).getTime() / 1000;
        ret = ret.filter((activity) => activity.start < dat);
      }

      return ret;
    },
  },

  methods: {
    hhmmss,
    /**
     * Deletes an activity. If an error occurs, a snackbar will be shown
     * @param item Object of the activity
     */
    deleteActivity(item) {
      this.user.DeleteActivity(item)
        .catch((err) => {
          EventBus.$emit('new-snackbar', err.toString(), 'error', 5000, true);
        });
    },
  },
};
</script>
